import { gql, useLazyQuery } from '@apollo/client';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { RHFSelect } from 'components/common/form';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';

/* -------------------------------------------------------------------------- */
export const GET_DONORS = gql`
  query GetDonors($where: donars_bool_exp!) {
    donars(where: $where, order_by: { created_at: desc }) {
      first_name
      id
      last_name
      email
      stripe_id
      phone
      address
      # image
      # score
    }
  }
`;
/* -------------------------------------------------------------------------- */

const ADSelectDonor = ({
  title = 'Select Donor',
  editable = true
  // selectedDonorId,
  // setSelectedDonorId
}) => {
  const { where } = useBranchPermission();
  const [getDonors, { data: donorsData, loading: donorsLoading }] =
    useLazyQuery(GET_DONORS);

  useEffect(() => {
    getDonors({
      variables: { where }
    });
  }, [where]);

  const customFilter = (option, searchText) => {
    if (option.data.name.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };
  const donorsOptions = useMemo(() => {
    if (!donorsData?.donars?.length) {
      return [];
    }

    return donorsData.donars.map(donor => {
      const name = donor.first_name + ' ' + donor.last_name;
      const id = donor.id;
      const profileImage = donor.image;
      const score = donor.score ?? 0;

      return {
        value: id,
        label: (
          <Flex alignItems="center">
            {profileImage ? (
              <Avatar
                src={profileImage}
                name={name}
                size="2xl"
                className="me-2"
              />
            ) : (
              <Avatar size="2xl" name={name} className="me-2" />
            )}
            <div className="ms-2">
              <h6 className="mb-0">{name}</h6>
              <p className="fs--2 mb-0 text-500">score: {score}</p>
            </div>
          </Flex>
        ),
        name
      };
    });
  }, [donorsData]);
  return (
    <Card className="mb-3">
      <Card.Header className="pb-0">
        <Card.Title className="fw-medium">{title}</Card.Title>
      </Card.Header>
      <Card.Body>
        {editable && (
          // <Form.Group className="mb-3" controlId="Donor_Name">
          //   <Form.Label>Donor Name</Form.Label>
          //   <Form.Control
          //     required
          //     type="text"
          //     placeholder="Zahran Amir Bassam"
          //   />
          //   <Form.Control.Feedback type="invalid">
          //     Donor Name required
          //   </Form.Control.Feedback>
          // </Form.Group>
          <RHFSelect
            label="Donor Name"
            name="donorId"
            isLoading={donorsLoading}
            options={donorsOptions}
            filterOption={customFilter}
            required
            className="mb-3"
          />
        )}

        {/* <DonorCard editable={editable} name="Zahran Amir Bassam" score={123} /> */}
      </Card.Body>
    </Card>
  );
};

ADSelectDonor.propTypes = {
  title: PropTypes.string,
  editable: PropTypes.bool
};

export default ADSelectDonor;
