import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import Flex from 'components/common/Flex';
import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import LoadingButton from 'components/common/LoadingButton';
import { useEffect } from 'react';

const schema = Yup.object().shape({
  description: Yup.string().required('Required Field'),
  priority: Yup.string().required('Required Field')
});

function DonorFeedbackFormModal({
  show,
  onHide,
  isEdit,
  initialValues,
  onSubmit,
  loading
}) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialValues ?? {
      description: '',
      priority: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch, reset } = methods;
  const { description, priority } = watch();
  useEffect(() => {
    reset();
  }, [show, reset]);
  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const headerText = isEdit ? 'Edit Comment' : 'Create Comment';
  const submitText = isEdit ? 'Save' : 'Create';
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={3} direction="column" className="mt-3">
            <RHFControl
              name="description"
              placeholder="Feedback"
              autoFocus
              as={'textarea'}
            />
            <RHFSelect
              name="priority"
              placeholder="Priority"
              options={[
                { label: 'Important', value: 'Important' },
                { label: 'Fair', value: 'Fair' },
                { label: 'Ok', value: 'Ok' }
              ]}
            />

            <LoadingButton
              type="submit"
              color="primary"
              className="w-100"
              disabled={!description || !priority}
              loading={loading}
            >
              {submitText}
            </LoadingButton>
          </Flex>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
}

DonorFeedbackFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default DonorFeedbackFormModal;
