import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import paths from 'routes/paths';
// import SingleImage from './Image';
// import product2 from 'assets/img/products/2.jpg';

const ImageList = ({
  ImageObj,
  index,
  setSelectedId,
  setShowDeleteImageModal
}) => {
  const { id } = useParams();

  const {
    created_at,
    user,
    gallery_image,
    image_description,
    id: imageId
  } = ImageObj;

  return (
    <>
      <Col
        xs={12}
        className={classNames('p-x1', {
          'bg-100': index % 2 !== 0
        })}
      >
        <Row>
          <Col sm={5} md={2}>
            <Image
              src={gallery_image}
              rounded={true}
              className={classNames('h-100 w-100 fit-cover')}
            />
          </Col>
          <Col sm={7} md={10}>
            <p className="mt-3 mt-sm-0 text-primary">{image_description}</p>
            <div className=" mt-0 d-flex">
              <p className="mb-2 mb-md-3">{created_at.split('T')[0]} By </p>{' '}
              <p className="mb-1 mb-md-1 text-primary">
                <Link to={paths.beneficiaries + '/' + id}>
                  {'  ' + user.first_name + ' ' + user.last_name}
                </Link>
              </p>
            </div>
            <Button
              variant="link"
              className="ps-0"
              style={{ fontSize: '12px' }}
              onClick={() => {
                setSelectedId(imageId);
                setShowDeleteImageModal(true);
              }}
            >
              Remove
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  );
};

ImageList.propTypes = {
  ImageObj: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    created_at: PropTypes.date,
    owner: PropTypes.string,
    gallery_image: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object,
    image_description: PropTypes.string
  }),
  index: PropTypes.number,
  setSelectedId: PropTypes.func,
  setShowDeleteImageModal: PropTypes.func
};

export default ImageList;
