import { gql, useLazyQuery, useMutation } from '@apollo/client';
import client from 'client/apolloClient';
import LoadingScreen from 'components/common/LoadingScreen';
import usePersistState from 'hooks/usePersistState';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { AbilityContext } from 'routes/permissions/Can';
import defineAbilityFor from 'routes/permissions/ability';

/* -------------------------------------------------------------------------- */
/*                                  CONSTANTS                                */
/* -------------------------------------------------------------------------- */
export const LOCAL_STORE_ACCESS_TOKEN = 'access_token';
export const LOCAL_STORE_EXPIRES_IN = 'expires_in';
export const LOCAL_STORE_REFRESH = 'refresh_token';
export const LOCAL_STORE_TOKEN_TYPE = 'token_type';
export const LOCAL_STORE_USER_ID = 'user_id';

export const ROLE_ORGANIZATION_ADMIN = 'organisationAdmin';
export const ROLE_BRANCH_STAFF = 'branch_staff';
export const ROLE_BRANCH_ADMIN = 'branch_admin';

export const rolesMap = {
  organisationAdmin: {
    id: 'd215c1df-3837-4af1-b03a-24c06d26ee94',
    name: 'Organisation Admin'
  },
  staff: {
    id: '0ca298c1-97c0-40df-9476-ae1c4ed5e973',
    name: 'Staff'
  },
  admin: {
    id: '2ebef1ca-9a0e-4a7b-8629-b9beef97063b',
    name: 'Branch Admin'
  }
};

export const LOCAL_STORE_BRANCH_ID = 'branch_id';
/* -------------------------------------------------------------------------- */
/*                             MUTATIONS and QUERIES                          */
/* -------------------------------------------------------------------------- */

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    Login(password: $password, email: $email) {
      access_token
      email
      expires_in
      phone
      refresh_token
      status
      token_type
      user {
        created_at
        email
        email_verified_at
        first_name
        id
        last_name
        middle_name
        otp
        phone
        otp_verified_at
        profile_pic
        updated_at
        # organisation_id
      }
    }
  }
`;

// creates user + organisation admin user role (no organisation)
const REGISTER_OWNER = gql`
  mutation RegisterOwner(
    $companyEmail: String = ""
    $email: String = ""
    $lastName: String = ""
    $firstName: String = ""
    $password: String = ""
    $phoneNumber: String = ""
    $title: String = ""
  ) {
    OrganisationSignup(
      comapny_email: $companyEmail
      email: $email
      last_name: $lastName
      password: $password
      phone: $phoneNumber
      title: $title
      first_name: $firstName
    ) {
      user {
        id
        updated_at
        phone
        last_name
        first_name
        email_verified_at
        email
        created_at
      }
      token_type
      status
      refresh_token
      phone
      expires_in
      email
      access_token
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: uuid!) {
    users_by_pk(id: $id) {
      id
      email
      first_name
      last_name
      email_verified_at
      users_roles {
        id
        branch_id
        organisation_id
        role_id
        is_active
        role {
          name
        }
        branch {
          address
          city
          created_at
          currency_id
          id
          location_id
          logo
          name
          organisation_id
          phone
          is_main
        }
        permissions
      }
      organisation {
        id
        post_code
        parent_id
        organisation_name
        organisation_email
        logo
        currency_id
        created_at
        country
        city
        address_2
        address_1
        charity_number
        stripe_key
        stripe_secret
      }
      organisation_id
      title
      stripe_id
    }
  }
`;

// const dummyUser = {
//   id: 'f489052b-3d9f-459e-9047-f8705c920509',
//   email: 'test@gmao.com',
//   first_name: 'John',
//   last_name: 'Doe',
//   users_roles: [
//     {
//       id: 'dasdaasd-3d9f-459e-9047-f8705c920509',
//       branch_id: 'f4890sdb-3d9f-459e-9047-f8705c920509',
//       organisation_id: 'f489052b-3d9f-459e-9047-f8705c920509',
//       role_id: 'f489052b-3d9f-459e-9047-f8705c920509',
//       is_active: true,
//       role: {
//         name: 'Admin'
//       },
//       branch: {
//         name: 'Branch 1',
//         id: '5s5d41a6-3d9f-459e-9047-f8705c920509'
//       },
//       permissions: {
//         dashboard: { browse: true, delete: true, edit: true, insert: true },
//         donor: { browse: true, delete: true, edit: true, insert: true },
//         project: { browse: true, delete: true, edit: true, insert: true },
//         sponsorship: { browse: true, delete: true, edit: true, insert: true }
//       }
//     }
//   ]
// };

/* -------------------------------------------------------------------------- */
/*                                  CONTEXTS                                 */
/* -------------------------------------------------------------------------- */

const AuthContext = createContext({
  is_main: false,
  organization: null,
  user: null,
  selectedBranchId: null,
  selectedUserRole: null,
  isAdmin: true,
  isOrganizationAdmin: false,
  login: () => {},
  logout: () => {},
  registerOwner: () => {},
  isAuthenticated: false,
  isInitialized: false,
  setOrganization: () => {},
  selectBranch: () => {},
  selectAdminBranch: () => {},
  removeBranchSelection: () => {},
  ability: null,
  initializeUser: () => {}
});
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthContextProvider');
  }
  return context;
};
/* -------------------------------------------------------------------------- */

export const AuthContextProvider = ({ children }) => {
  // const [user, setUser] = useState();
  const [organization, setOrganization] = useState();
  const location = useLocation();
  // const [isOrganizationAdmin, setIsOrganizationAdmin] = useState(false);
  // const [branch, setBranch] = useState({
  //   id: '55169c57-195e-46a3-8302-40a47df431c0'
  // });
  const [userRole, setUserRole] = usePersistState('userRole', {
    selectedBranchId: null,
    selectedUserRole: null,
    isAdmin: false,
    isOrganizationAdmin: false,
    permissions: null,
    role: null,
    is_main: false
  });
  const {
    selectedBranchId,
    selectedUserRole,
    isAdmin,
    isOrganizationAdmin,
    permissions,
    role,
    is_main
  } = userRole;
  const navigate = useNavigate();
  const [loginMutation] = useMutation(LOGIN, {
    onCompleted: async data => {
      const { access_token, expires_in, refresh_token, token_type, user } =
        data.Login;
      if (access_token) {
        // save to local storage
        localStorage.setItem(LOCAL_STORE_ACCESS_TOKEN, access_token);
        localStorage.setItem(LOCAL_STORE_EXPIRES_IN, expires_in);
        localStorage.setItem(LOCAL_STORE_REFRESH, refresh_token);
        localStorage.setItem(LOCAL_STORE_TOKEN_TYPE, token_type);
        localStorage.setItem(LOCAL_STORE_USER_ID, user.id);
        // setIsAuthenticated(true);
        const { organization } = await initializeUser();
        // console.log({ organization });
        // setOrganization(organization);
        if (organization) {
          navigate(paths.branchSelection);
        } else {
          navigate(paths.registerBusinessInfo);
        }
      } else {
        toast.error('Wrong email or password');
      }
    },
    onError: async ({ graphQLErrors }) => {
      toast.error(graphQLErrors[0].message);
      console.log(graphQLErrors);
    }
  });
  const [registerOwnerMutation] = useMutation(REGISTER_OWNER, {
    onCompleted: async data => {
      if (data.OrganisationSignup.access_token) {
        const { access_token, expires_in, refresh_token, token_type, user } =
          data.OrganisationSignup;
        // save to local storage
        localStorage.setItem(LOCAL_STORE_ACCESS_TOKEN, access_token);
        localStorage.setItem(LOCAL_STORE_EXPIRES_IN, expires_in);
        localStorage.setItem(LOCAL_STORE_REFRESH, refresh_token);
        localStorage.setItem(LOCAL_STORE_TOKEN_TYPE, token_type);
        localStorage.setItem(LOCAL_STORE_USER_ID, user.id);
        await initializeUser();
        // setUser(user);
        setIsAuthenticated(true);
        setIsInitialized(true);
        navigate(paths.registerBusinessInfo);
      }
    },
    onError: async ({ graphQLErrors }) => {
      toast.error(graphQLErrors[0].message);
      console.log(graphQLErrors);
    }
  });
  // const [logoutMutation] = useMutation(LOG_OUT);
  const [getUsers, { data: userData, loading: userLoading }] = useLazyQuery(
    GET_USER,
    {
      fetchPolicy: 'cache-and-network'
    }
  );
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // useEffect(() => {
  //   setUser(userData?.users_by_pk ?? {});
  // }, [userData]);

  // const organization = useMemo(
  //   () =>
  //     userData?.users_by_pk?.organisation ?? {
  //       id: 'f489052b-3d9f-459e-9047-f8705c920509'
  //     },
  //   [userData]
  // );
  // const branch = useMemo(
  //   () => ({
  //     id: '55169c57-195e-46a3-8302-40a47df431c0'
  //   }),
  //   [userData]
  // );

  const initializeUser = async () => {
    let organization = null;
    const token = localStorage.getItem(LOCAL_STORE_ACCESS_TOKEN);
    const userId = localStorage.getItem(LOCAL_STORE_USER_ID);
    // setIsInitialized(true);
    if (userId && token) {
      const { error, data } = await getUsers({
        variables: { id: userId }
      });
      if (error) {
        await logout();
      } else {
        // user = userData.users_by_pk;
        // organization = userData.users_by_pk.organisation;
        // setUser(user);
        // setOrganization(organization);
        organization = data.users_by_pk.organisation;
        const isOrganizationAdmin = data.users_by_pk.users_roles.some(
          userRole =>
            userRole.role_id === rolesMap?.organisationAdmin?.id &&
            userRole.branch_id === null &&
            userRole.organisation_id === organization?.id
        );
        if (isOrganizationAdmin) {
          setUserRole(prev => ({
            ...prev,
            isAdmin: isOrganizationAdmin,
            isOrganizationAdmin: isOrganizationAdmin,
            role: ROLE_ORGANIZATION_ADMIN
          }));
        }
        setOrganization(organization);
        // match paths.verifyEmail path with matchRoutes function from react-router
        // const isVerifyEmailPath = location.pathname.match(paths.verifyEmail);
        const matches = matchRoutes(
          [
            {
              path: paths.verifyEmail
            }
          ],
          location.pathname
        );
        console.log({
          pathname: location.pathname,
          verifyEmail: paths.verifyEmail,
          matches
        });
        if (organization && !data.users_by_pk.email_verified_at && !matches) {
          navigate(paths.verifyEmailAlert);
        }
        if (organization && data.users_by_pk.email_verified_at && matches) {
          navigate(paths.dashboard);
        }
        setIsAuthenticated(true);
      }
    } else {
      setIsAuthenticated(false);
    }
    setIsInitialized(true);
    return { organization };
  };
  useEffect(() => {
    initializeUser();
  }, []);
  const registerOwner = async ({
    title,
    firstName,
    lastName,
    phoneNumber,
    email,
    companyEmail,
    password
  }) => {
    await registerOwnerMutation({
      variables: {
        title,
        firstName,
        lastName,
        phoneNumber,
        email,
        companyEmail,
        password
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const login = async ({ email, password, remember }) => {
    await loginMutation({
      variables: {
        password: password,
        email: email
      }
    });
  };
  const logout = async ({ sessionOut }) => {
    // try {
    //   await logoutMutation({
    //     variables: {
    //       userId: user.id
    //     }
    //   });
    // } catch (error) {
    //   console.log(error);
    // }
    client.clearStore();
    client.cache.gc();
    localStorage.removeItem(LOCAL_STORE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORE_EXPIRES_IN);
    localStorage.removeItem(LOCAL_STORE_REFRESH);
    localStorage.removeItem(LOCAL_STORE_TOKEN_TYPE);
    localStorage.removeItem(LOCAL_STORE_USER_ID);
    setIsAuthenticated(false);
    if (sessionOut === true) {
      navigate(paths.sessionOut);
    } else navigate(paths.login);
    // setUser(null);
    // setOrganization(null);
    // dispatch(userActions.logout());
  };
  const selectAdminBranch = ({ selectedBranchId, is_main }) => {
    setUserRole(prev => ({
      ...prev,
      selectedBranchId,
      is_main
    }));
  };

  const selectBranch = ({ userRole, is_main }) => {
    if (!userRole) {
      setUserRole({
        selectedBranchId: null,
        selectedUserRole: null,
        isAdmin: false,
        isOrganizationAdmin: false,
        permissions: null,
        role: null,
        is_main: is_main
      });
      return;
    }
    const isBranchAdmin = userRole.role_id === rolesMap.admin.id;
    setUserRole({
      selectedBranchId: userRole.branch_id,
      selectedUserRole: userRole,
      isAdmin: isBranchAdmin,
      isOrganizationAdmin: false,
      permissions: userRole.permissions,
      role: isBranchAdmin ? ROLE_BRANCH_ADMIN : ROLE_BRANCH_STAFF,
      is_main: userRole.is_main
    });
  };
  const removeBranchSelection = () => {
    setUserRole(prev => ({
      ...prev,
      selectedBranchId: null,
      selectedUserRole: null,
      isAdmin: isOrganizationAdmin,
      permissions: null
    }));
  };
  // const dummyUserRole = {
  //   permissions: backendPermissions,
  //   role: 'staff'
  // };
  const ability = useMemo(() => {
    // if (selectedBranchId) {
    return defineAbilityFor(role, permissions);
    // }
  }, [permissions, role]);

  if (userLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <AuthContext.Provider
        value={{
          organization,
          user: userData?.users_by_pk ?? {},
          // remove this
          // user: dummyUser || userData?.users_by_pk,
          selectedBranchId,
          selectedUserRole,
          isAdmin,
          isOrganizationAdmin,
          is_main,
          login,
          logout,
          registerOwner,
          isAuthenticated,
          isInitialized,
          setOrganization,
          selectBranch,
          selectAdminBranch,
          removeBranchSelection,
          ability,
          initializeUser
        }}
      >
        <AbilityContext.Provider value={ability}>
          {children}
        </AbilityContext.Provider>
      </AuthContext.Provider>
    </>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
