import { gql, useMutation } from '@apollo/client';
import AuthContext from 'context/authContext';
import useBranchPermission from 'hooks/useBranchPermission';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { GET_BENEFACTORS } from '.';
import BeneficiaryForm from './beneficiary-form/BeneficiaryForm';

const CREATE_BENEFICIARY = gql`
  mutation CreateBeneficiary(
    $DOB: timestamp = ""
    $first_name: String = ""
    $last_name: String = ""
    $middle_name: String = ""
    $gender: String = ""
    $language: String = ""
    $country: String = ""
    $city: String = ""
    $location_condition: String = ""
    $sponsorship_condition_id: uuid = ""
    $status: String = ""
    $health_notes: String = ""
    $inspiration: String = ""
    $cost: jsonb = ""
    $duration: jsonb = ""
    $sponsorship_type_id: uuid = ""
    $sponsorship_priority_id: uuid = ""
    $image: String = ""
    $organisation_id: uuid = ""
    $branch_id: uuid = ""
    $guardian: guardian_insert_input = {
      email: ""
      first_name: ""
      last_name: ""
      middle_name: ""
      mother_status: ""
      phone: ""
      relationship: ""
    }
  ) {
    insert_orphans_one(
      object: {
        DOB: $DOB
        first_name: $first_name
        middle_name: $middle_name
        last_name: $last_name
        gender: $gender
        language: $language
        country: $country
        city: $city
        location_condition: $location_condition
        sponsorship_condition_id: $sponsorship_condition_id
        status: $status
        health_notes: $health_notes
        inspiration: $inspiration
        cost: $cost
        duration: $duration
        sponsorship_type_id: $sponsorship_type_id
        sponsorship_priority_id: $sponsorship_priority_id
        image: $image
        organisation_id: $organisation_id
        branch_id: $branch_id
        guardian: { data: $guardian }
      }
    ) {
      first_name
      middle_name
      gender
      country
      DOB
      gender
      sponsorship_condition {
        condition
      }
      sponsorship_priority {
        priority_type
      }
      sponsorship_type {
        type
      }
      id
      last_name
      image
    }
  }
`;

function CreateBeneficiary() {
  const navigate = useNavigate();

  const { organization, selectedBranchId } = useContext(AuthContext);
  const { where } = useBranchPermission();

  const [createBeneficiary, { loading }] = useMutation(CREATE_BENEFICIARY, {
    onCompleted: () => {
      toast.success('Beneficiary created successfully');
      navigate(paths.beneficiaries);
    },
    onError: error => {
      toast.error(error.message);
      console.log(error);
    },
    update: (cache, data) => {
      const newBeneficiary = data.data.insert_orphans_one;
      const existingBeneficiaries = cache.readQuery({
        query: GET_BENEFACTORS,
        variables: { where }
      });
      if (existingBeneficiaries) {
        cache.writeQuery({
          query: GET_BENEFACTORS,
          variables: { where },
          data: {
            orphans: [newBeneficiary, ...existingBeneficiaries.orphans]
          }
        });
      }
    }
  });
  const onSubmit = data => {
    createBeneficiary({
      variables: {
        ...data,
        organisation_id: organization.id,
        branch_id: selectedBranchId
      }
    });
  };

  return <BeneficiaryForm onSubmit={onSubmit} loading={loading} />;
}

export default CreateBeneficiary;
