/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  InputGroup,
  Row,
  Nav
} from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import Locations, { locationTypesMap } from './Locations';
import ProjectCategories from './ProjectCategories';
import DonationTypes from './DonationType';
import DonationAmounts from './DonationAmounts';
import TransactionFees from './TransactionFees';
import ProjectCurrencies from './ProjectCurrencies';
import Campaigns from './Campaigns';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import { Can } from 'routes/permissions/Can';

const SUCCESSREDIRECTION = {
  title: 'Success Redirection',
  value: '',
  check: false
};

export const donationSettingsSectionsRefMap = {
  location: '#location',
  category: '#category',
  campaigns: '#campaigns',
  donationType: '#donationType',
  donationAmount: '#donationAmount',
  transactionFee: '#transactionFee',
  projectCurrency: '#projectCurrency',
  successRedirection: '#successRedirection'
};

const DonationSettings = () => {
  const [successRedirection, setSuccessRedirection] =
    useState(SUCCESSREDIRECTION);

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  const sideNavLinks = [
    {
      id: 1,
      href: donationSettingsSectionsRefMap.location,
      title: 'Projects Location'
    },
    {
      id: 2,
      href: donationSettingsSectionsRefMap.category,
      title: 'Project Category'
    },
    {
      id: 3,
      href: donationSettingsSectionsRefMap.campaigns,
      title: 'Campaigns'
    },
    {
      id: 4,
      href: donationSettingsSectionsRefMap.donationType,
      title: 'Donation Type'
    },
    {
      id: 5,
      href: donationSettingsSectionsRefMap.donationAmount,
      title: 'Donation Amounts'
    },
    {
      id: 6,
      href: donationSettingsSectionsRefMap.transactionFee,
      title: 'Add transaction Fee'
    },
    {
      id: 7,
      href: donationSettingsSectionsRefMap.projectCurrency,
      title: 'Projects Currency'
    },
    {
      id: 8,
      href: donationSettingsSectionsRefMap.successRedirection,
      title: 'Success Redirection'
    }
  ];

  const handleChange = text => {
    if (text !== '') {
      setSuccessRedirection(text);
    }
  };

  return (
    <Row className="pt-3 gx-3">
      <Col md={9}>
        {/* {cardsData.map((card, index) => {
          return (
            <>
              <DonationCard
                sectionRef={sectionRefs[index + 1]}
                sideNavLinks={sideNavLinks[index]?.href?.substring(1)}
                card={card}
                addData={addData}
                editData={editData}
                deleteData={deleteData}
              />
            </>
          );
        })} */}
        <Locations
          sectionRef={sectionRefs[1]}
          sideNavLink={sideNavLinks[0]?.href?.substring(1)}
          type={locationTypesMap.project}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <ProjectCategories
          sectionRef={sectionRefs[2]}
          sideNavLink={sideNavLinks[1]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <Campaigns
          sectionRef={sectionRefs[3]}
          sideNavLink={sideNavLinks[2]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />

        <DonationTypes
          sectionRef={sectionRefs[4]}
          sideNavLink={sideNavLinks[3]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <DonationAmounts
          sectionRef={sectionRefs[5]}
          sideNavLinks={sideNavLinks[4]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <TransactionFees
          sectionRef={sectionRefs[6]}
          sideNavLinks={sideNavLinks[5]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />

        <ProjectCurrencies
          sectionRef={sectionRefs[7]}
          sideNavLinks={sideNavLinks[6]?.href?.substring(1)}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        <SuccessRedirection
          sectionRef={sectionRefs[7]}
          sideNavLinks={sideNavLinks[7]?.href?.substring(1)}
          data={successRedirection}
          // handleCheck={handleCheck}
          handleChange={handleChange}
          subject={permissionsKeyMap.donation.children.donationSettings}
        />
        {/* <Card>
          <Card.Body className="d-flex flex-row justify-content-end">
            <Button
              variant="primary"
              className="w-100 w-sm-25"
              onClick={() => setModal(true)}
            >
              Update
            </Button>
          </Card.Body>
        </Card> */}
      </Col>
      <Col md={3} className="order-0 order-md-1 d-none d-md-block">
        <Card
          className={'mb-3 sticky-top border shadow-none'}
          style={{ top: stickyStatus ? '5rem' : '0' }}
        >
          <Card.Body>
            <div>
              <Nav className="flex-column" variant="pills">
                {sideNavLinks?.map(item => (
                  <Nav.Link
                    key={item.id}
                    href={item.href}
                    className={`${
                      activeSection === item.id
                        ? 'current custom-nav-link'
                        : 'custom-nav-link'
                    }`}
                    active={activeSection >= item.id}
                  >
                    {item.title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col>

      {/* <DataModal
        show={modal}
        setShow={setModal}
        page={'donation_settings'}
        data={{
          ...cardsData,
          4: donationAmounts,
          5: transactionFee,
          6: projectsCurrency,
          7: successRedirection
        }}
      /> */}
    </Row>
  );
};

const SuccessRedirection = ({
  card = {
    title: 'Success Redirection',
    id: 'Success Redirection',
    placeholder: 'Donation Success Redirect Page',
    input_title: 'Donation Success Redirect Page',
    current_title: 'Donation Success Redirect Page',
    current_values: []
  },
  data,
  handleChange,
  sectionRef,
  sideNavLink,
  subject
  // handleCheck
}) => {
  return (
    <>
      <Card
        key={card.id}
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <Card.Header>
          <Row>
            <Col>
              <Card.Title>{card.title}</Card.Title>
            </Col>
            {/* <Col>
              <Card.Title style={{ textAlign: 'right' }}>
                <Form.Group as={Row} className="mb-3">
                  <Col>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      value={data?.check}
                      onChange={() => handleCheck(card?.id)}
                    />
                  </Col>
                </Form.Group>
              </Card.Title>
            </Col> */}
          </Row>
        </Card.Header>
        {/* {data?.check ? ( */}
        <Card.Body>
          <FormGroup controlId={card.id}>
            <Form.Label>{card.input_title}</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={card.placeholder}
                aria-label={card.placeholder}
                name={card.id}
                type="text"
                value={data?.value}
                onChange={e => handleChange(e?.target?.value)}
              />
              <Can I={userActions.update} a={subject}>
                <Button
                  id={card.id}
                  variant="light"
                  className="border bg-transparent shadow-none"
                  style={{
                    fontWeight: 600
                  }}
                >
                  Save
                </Button>
              </Can>
            </InputGroup>
          </FormGroup>
        </Card.Body>
        {/* ) : null} */}
      </Card>
    </>
  );
};

export default DonationSettings;
