import React, { useContext, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useAuthContext } from 'context/authContext';

// eslint-disable-next-line react/prop-types
const MainLayout = ({ type }) => {
  const { hash, pathname } = useLocation();
  const { breakpoints } = useBreakpoints();
  const { isOrganizationAdmin } = useAuthContext();
  const isMediumScreen = breakpoints.up('md');
  const {
    config: { isFluid }
  } = useContext(AppContext);
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            block: 'start',
            behavior: 'smooth'
          });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const showNavbarVertical =
    type !== 'organization' || (type === 'organization' && isOrganizationAdmin);
  const parentRef = useRef(null);

  const [isWidthReady, setIsWidthReady] = useState(false);

  const [parentWidth, setParentWidth] = useState(0);
  useEffect(() => {
    if (!isWidthReady) return;
    setParentWidth(parentRef?.current?.clientWidth);
  }, [isWidthReady]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {showNavbarVertical && <NavbarVertical type={type} />}
      <div
        className={classNames('content pb-3')}
        ref={element => {
          parentRef.current = element;
          setIsWidthReady(true);
        }}
      >
        {parentWidth && (
          <NavbarTop
            type={type}
            isMediumScreen={isMediumScreen}
            width={parentWidth}
          />
        )}
        {/* <WeRaiseTopNav /> */}
        <div style={{ marginTop: '75px' }}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
