import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFDatePicker, RHFSelect } from 'components/common/form';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { GET_SCHOOLS } from '../queries';

/*--------------------------------------------------- */

const schema = Yup.object().shape({
  school: Yup.string().required('Required Field')
});

/*--------------------------------------------------- */
function AddSchoolModal({ show, onSubmit, onHide, title, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      school: '',
      startDate: '',
      endDate: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit } = methods;
  const { where } = useBranchPermission();

  const [getSchool, { data: schoolData }] = useLazyQuery(GET_SCHOOLS);

  useEffect(() => {
    getSchool({ variables: { where } });
  }, [where]);

  const schoolOptions = useMemo(() => {
    return schoolData?.schools.map(item => {
      return { value: item.id, label: item.school_name };
    });
  }, [schoolData]);

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3 pe-3">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Flex gap={3} direction="column" className="mt-3">
              <Row>
                <Col md={12}>
                  <RHFSelect
                    name={'school'}
                    label={'School'}
                    options={schoolOptions}
                    required={true}
                  />
                </Col>
                <Col md={6}>
                  <RHFDatePicker
                    name={'startDate'}
                    label={'Start Date'}
                    required={true}
                  />
                </Col>
                <Col md={6}>
                  <RHFDatePicker
                    name={'endDate'}
                    label={'End Date'}
                    required={true}
                  />
                </Col>
              </Row>
              <LoadingButton
                style={{ width: '100%', color: 'white' }}
                variant="primary"
                loading={loading}
                disabled={loading}
                className="w-100"
                type="submit"
              >
                {'Save'}
              </LoadingButton>
            </Flex>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
AddSchoolModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  onSubmit: PropTypes.func
};
export default AddSchoolModal;
