import { titleOptions } from 'components/authenitcation/registration/OwnerRegistrationForm';
import { RHFCheck, RHFControl, RHFSelect } from 'components/common/form';
import React from 'react';
import { Card, Col, FormGroup, Row } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
const NameInputs = ({ individual, setIndividual }) => {
  return (
    <Card className="mb-3">
      <Card.Header className="d-flex">
        <Card.Title className="fw-medium">Donor Details</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row className="g-3">
          <Col md="6">
            <FormGroup>
              <RHFCheck
                label={'Individual'}
                type="radio"
                name={'individual'}
                checked={individual}
                onChange={() => {
                  setIndividual(!individual);
                }}
              />

              <RHFCheck
                label={'Company'}
                type="radio"
                name={'company'}
                checked={!individual}
                onChange={() => {
                  setIndividual(!individual);
                }}
              />
            </FormGroup>{' '}
          </Col>
          <Col md="6">
            <RHFCheck name="is_donor" label="Is donor" type="checkbox" />
            <RHFCheck name="is_sponsor" label="Is sponsor" type="checkbox" />
            <RHFCheck name="is_partner" label="Is partner" type="checkbox" />
          </Col>
          <Col md="6">
            <RHFSelect
              name="title"
              label="Title"
              options={titleOptions}
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              placeholder={'Username'}
              name="username"
              label="Username"
              required
            />
          </Col>{' '}
          <Col md="6">
            <RHFControl
              name="firstName"
              placeholder="First name"
              label="First name"
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              name="lastName"
              placeholder="Last name"
              label="Last name"
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              name="tax_vat_number"
              placeholder="Tax ID"
              label="Tax ID"
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              name="charity_registration_number"
              placeholder="Charity Registration Number"
              label="Charity Registration Number"
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              name="website"
              placeholder="Website Link"
              label="Website Link"
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              name="job_title"
              placeholder="Job Title"
              label="Job Title"
              disabled={!individual}
              required
            />
          </Col>
          <Col md="6">
            <RHFControl
              name="partner_name"
              placeholder="Company Name"
              label="Company Name"
              required
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NameInputs;
