import { Card, Col, Row } from 'react-bootstrap';
import { RHFDatePicker, RHFSelect } from 'components/common/form';

const generateData = (length = 12) => {
  const value = [];
  for (let i = 1; i <= length; i++) {
    const data = { value: i, label: i };
    value.push(data);
  }
  return value;
};

const LeadSource = () => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <Card.Title className="fw-medium">
          Lead Source <span className="text-400">(Optional)</span>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={6} className="mb-3">
                <RHFSelect
                  name={'leadSource.source'}
                  label="Source"
                  options={generateData(100)}
                />
              </Col>
              <Col md={6} />
              <Col md="4" className="mb-3 mb-md-0" controlId="Consented_Date">
                <RHFDatePicker
                  name={'leadSource.date'}
                  label="Date"
                  placeholderText="DD/MM/YYYY"
                />
              </Col>
              <Col md="4" controlId="Consented_Time">
                <RHFDatePicker
                  name={'leadSource.time'}
                  label="Time"
                  dateFormat="h:mm aa"
                  placeholderText="00:00:00"
                  timeIntervals={1}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LeadSource;
