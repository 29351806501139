import { gql, useMutation, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { ToDoListItem } from 'components/we-raise-common/ToDoList';
import { useAuthContext } from 'context/authContext';
import { useDonorContext } from 'context/donorContext';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import AssignedStaffCard from './relationManagement/AssignedStaffCard';
import CommentFormModal from './relationManagement/CommentFormModal';
import DonorCommentsTable, {
  GET_DONOR_COMMENTS
} from './relationManagement/DonorCommentsTable';
import DonorTaskFormModal from './relationManagement/DonorTaskFormModal';
import SelectStaffModal from './relationManagement/SelectStaffModal';

// ----------------------------------------------------
const CREATE_DONOR_COMMENT = gql`
  mutation CreateDonorComment(
    $description: String!
    $donor_id: uuid!
    $reporter_id: uuid!
  ) {
    insert_donor_comments_one(
      object: {
        donor_id: $donor_id
        description: $description
        reporter_id: $reporter_id
      }
    ) {
      reporter_id
      id
      donor_id
      description
      created_at
      user {
        first_name
        last_name
      }
    }
  }
`;

export const INSERT_DONOR_TASK = gql`
  mutation InsertDonarTask(
    $donor_id: uuid = ""
    $description: String = ""
    $assignee: uuid = ""
  ) {
    insert_donor_tasks_one(
      object: {
        is_complete: false
        donor_id: $donor_id
        description: $description
        assignee: $assignee
      }
    ) {
      description
      id
      donor_id
      is_complete
      assignee
    }
  }
`;

export const UPDATE_COMPLETE_TASK = gql`
  mutation UpdateCompleteDonarTask(
    $id: uuid = ""
    $is_complete: Boolean = false
  ) {
    update_donor_tasks_by_pk(
      pk_columns: { id: $id }
      _set: { is_complete: $is_complete }
    ) {
      description
      id
      donor_id
      is_complete
      assignee
    }
  }
`;

const GET_DONOR_TASK = gql`
  query GetDonorTask($donorId: uuid = "") {
    donor_tasks(where: { donor_id: { _eq: $donorId } }) {
      description
      id
      donor_id
      is_complete
      assignee
    }
  }
`;

export const DELETE_DONOT_TASK = gql`
  mutation DeleteDonorTask($id: uuid = "") {
    delete_donor_tasks_by_pk(id: $id) {
      id
    }
  }
`;

const EDIT_ASSIGNED_STAFF = gql`
  mutation EditAssignedStaff($donorId: uuid!, $assignedStaff: uuid!) {
    update_donars_by_pk(
      pk_columns: { id: $donorId }
      _set: { user_id: $assignedStaff }
    ) {
      first_name
      id
      last_name
      title
      user {
        first_name
        last_name
        id
      }
    }
  }
`;
// --------------------------------------------------

const DonorRelationManagement = () => {
  const {
    donor: { user: assignedStaff, id: donorId }
  } = useDonorContext();
  const { user } = useAuthContext();
  const [showCommentForm, setShowCommentForm] = useState(false);
  const [showAddDonorForm, setShowAddDonorForm] = useState(false);
  const [showSelectStaffModal, setShowSelectStaffModal] = useState(false);
  const [todoItems, setTodoItems] = useState([]);

  const { data: todoTasks } = useQuery(GET_DONOR_TASK, {
    variables: {
      donorId: donorId
    }
  });
  const [editAssignedStaff, { loading: editAssignedStaffLoading }] =
    useMutation(EDIT_ASSIGNED_STAFF, {
      onCompleted: () => {
        toast.success('Assigned Staff Updated');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again later');
        console.log(error);
      }
    });

  useEffect(() => {
    if (todoTasks?.donor_tasks) {
      setTodoItems(todoTasks?.donor_tasks);
    }
  }, [todoTasks]);

  const [createDonorComment, { loading: createCommentLoading }] = useMutation(
    CREATE_DONOR_COMMENT,
    {
      onCompleted: () => {
        setShowCommentForm(false);
        toast.success('Comment created successfully');
      },
      onError: () => {
        toast.error('Something went wrong! Please try again');
      },
      update: (cache, { data: { insert_donor_comments_one } }) => {
        const { donor_comments } = cache.readQuery({
          query: GET_DONOR_COMMENTS,
          variables: { donorId: donorId }
        });
        if (!donor_comments) return;
        cache.writeQuery({
          query: GET_DONOR_COMMENTS,
          variables: { donorId: donorId },
          data: {
            donor_comments: [insert_donor_comments_one, ...donor_comments]
          }
        });
      }
    }
  );

  const [deleteDonorTask] = useMutation(DELETE_DONOT_TASK, {
    onCompleted: () => {},
    update: (cache, { data: { delete_donor_tasks_by_pk } }) => {
      const { donor_tasks } = cache.readQuery({
        query: GET_DONOR_TASK,
        variables: { donorId: donorId }
      });
      if (!donor_tasks) return;
      const tmp = donor_tasks.filter(
        item => item.id !== delete_donor_tasks_by_pk.id
      );
      cache.writeQuery({
        query: GET_DONOR_TASK,
        variables: { donorId: donorId },
        data: {
          donor_tasks: tmp
        }
      });
    }
  });

  const [updateCompleteTask] = useMutation(UPDATE_COMPLETE_TASK, {
    onCompleted: () => {
      setShowCommentForm(false);
      toast.success('Task updated successfully');
    },
    onError: () => {
      toast.error('Something went wrong! Please try again');
    },
    update: (cache, { data: { update_donor_tasks_by_pk } }) => {
      const { donor_tasks } = cache.readQuery({
        query: GET_DONOR_TASK,
        variables: { donorId: donorId }
      });
      if (!donor_tasks) return;
      const tmp = donor_tasks.map(item => {
        if (item.id === update_donor_tasks_by_pk.id) {
          return update_donor_tasks_by_pk;
        }
        return item;
      });
      cache.writeQuery({
        query: GET_DONOR_TASK,
        variables: { donorId: donorId },
        data: {
          donor_tasks: tmp
        }
      });
    }
  });

  const handleChange = (id, completed) => {
    updateCompleteTask({
      variables: {
        is_complete: completed,
        id
      }
    });
  };

  const handleDelete = id => {
    deleteDonorTask({
      variables: {
        id
      }
    });
  };

  const [createDonorTask, { loading: insertDonorTaskLoading }] = useMutation(
    INSERT_DONOR_TASK,
    {
      onCompleted: () => {},
      update: (cache, { data: { insert_donor_tasks_one } }) => {
        const { donor_tasks } = cache.readQuery({
          query: GET_DONOR_TASK,
          variables: { donorId: donorId }
        });
        if (!donor_tasks) return;
        cache.writeQuery({
          query: GET_DONOR_TASK,
          variables: { donorId: donorId },
          data: {
            donor_tasks: [...donor_tasks, insert_donor_tasks_one]
          }
        });
      }
    }
  );

  const weDonorCommunicationData = [
    {
      title: 'Send E-Mail',
      text: 'Send email to the donor',
      icon: 'envelope',
      color: 'primary',
      disabled: true
    },
    {
      title: 'Start a meeting',
      text: 'Start or schedule a meeting',
      icon: 'video',
      color: 'warning',
      disabled: true
    },
    {
      title: 'Create a Task',
      text: 'Create a to do task',
      icon: 'clipboard',
      color: 'danger',
      onClick: () => setShowAddDonorForm(true)
    },
    {
      title: 'Start a Call',
      text: 'Log a call or start one',
      icon: 'phone-alt',
      color: 'success',
      disabled: true
    },
    {
      title: 'Notes',
      text: 'Add comments and remarks',
      icon: 'user-cog',
      color: '',
      onClick: () => setShowCommentForm(true)
    },
    {
      title: 'Contact owner',
      text: 'Assign a staff',
      icon: 'user-cog',
      color: 'info',
      onClick: () => openSelectStaffModal()
    }
  ];

  const handleCreateDonorComment = data => {
    createDonorComment({
      variables: {
        description: data.comment,
        donor_id: donorId,
        // fixed user id for now
        reporter_id: user.id
      }
    });
  };

  const handleCreateDonorTask = data => {
    setShowAddDonorForm(false);
    createDonorTask({
      variables: {
        description: data.description,
        donor_id: donorId,
        assignee: user.id
      }
    });
    // createDonorComment({
    //   variables: {
    //     description: data.comment,
    //     donor_id: donorId,
    //     // fixed user id for now
    //     reporter_id: 'ac476533-9197-4228-b5c7-879fd63e7bfb'
    //   }
    // });
  };
  const openSelectStaffModal = () => setShowSelectStaffModal(true);
  const closeSelectStaffModal = () => setShowSelectStaffModal(false);
  const onSubmitSelectStaffModal = ({ assignedStaff }) => {
    editAssignedStaff({
      variables: {
        donorId,
        assignedStaff
      }
    });

    closeSelectStaffModal();
  };
  return (
    <>
      {/* {showCommentForm && ( */}
      <CommentFormModal
        show={showCommentForm}
        onHide={() => setShowCommentForm(false)}
        onSubmit={handleCreateDonorComment}
        loading={createCommentLoading}
      />
      <SelectStaffModal
        show={showSelectStaffModal}
        onHide={closeSelectStaffModal}
        onSubmit={onSubmitSelectStaffModal}
        loading={editAssignedStaffLoading}
        initialValues={{
          assignedStaff: assignedStaff?.id
        }}
      />
      {/* )} */}
      {/* {showCommentForm && ( */}
      <DonorTaskFormModal
        show={showAddDonorForm}
        onHide={() => setShowAddDonorForm(false)}
        onSubmit={handleCreateDonorTask}
        loading={insertDonorTaskLoading}
      />
      {/* )} */}

      <Row className="g-3 mb-3">
        <Col xs={12}>
          <Card className="h-100">
            <FalconCardHeader
              title={
                <>
                  <h5 className="text-primary">
                    Welcome to Contact Relation management center!
                  </h5>
                  <h6 className="text-600">
                    Here are some quick links for you to start
                  </h6>
                </>
              }
              titleTag="div"
              className="pb-1"
              // menuClassName="align-items-start"
              // endEl={<CardDropdown />}
            />

            <Card.Body className="z-index-1">
              <Row className="g-2 h-100 align-items-end">
                {weDonorCommunicationData.map(
                  ({ icon, color, title, disabled, text, onClick }) => {
                    return (
                      <Col sm={6} md={4} key={title}>
                        <button
                          onClick={onClick}
                          className="btn d-flex justify-content-start position-relative mb-2 w-100"
                          disabled={disabled}
                        >
                          <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                            <FontAwesomeIcon
                              icon={icon}
                              className={`text-${color}`}
                            />
                          </div>
                          <div className="flex-1 text-start">
                            <Link to="#!" className="stretched-link text-800">
                              <h6 className="mb-0">{title}</h6>
                            </Link>
                            <p className="mb-0 fs--2 text-500 ">{text}</p>
                          </div>
                        </button>
                      </Col>
                    );
                  }
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="g-3 mb-3">
        <Col md={7}>
          <AssignedStaffCard handleSelectStaff={openSelectStaffModal} />
        </Col>
        <Col md={5}>
          <Card>
            <FalconCardHeader title="Tasks" titleTag="h6" />
            <Card.Body className="p-0 overflow-hidden">
              {todoItems.map((item, index) => (
                <ToDoListItem
                  key={item.id}
                  item={item}
                  index={index}
                  length={todoItems.length}
                  setTodoItems={setTodoItems}
                  handleChange={handleChange}
                  onDelete={handleDelete}
                />
              ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          <DonorCommentsTable donorId={donorId} />
        </Col>
      </Row>
    </>
  );
};

export default DonorRelationManagement;
