import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GET_BENEFICIARY_BY_ID,
  GET_SCHOOLS,
  GET_SCHOOL_BY_ID,
  GET_SCHOOL_STUDENT_BY_ID
} from '../queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import SchoolForm from './SchoolForm';
import {
  ADD_SCHOOL_STUDENTS,
  DELETE_SCHOOL_STUDENT_BY_SCHOOL,
  UPDATE_SCHOOL_BY_ID
} from '../mutations';
import { useAuthContext } from 'context/authContext';
import { toast } from 'react-toastify';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import TableRowAction from 'components/common/TableRowAction';
import SelectStudentModal from './SelectStudentModal';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import useBranchPermission from 'hooks/useBranchPermission';

function EditSchool() {
  const schoolColumns = [
    {
      accessor: 'full_name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'DOB',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { DOB } = rowData.row.original;
        return <>{dayjs(DOB).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender'
    },
    {
      accessor: 'created_at',
      Header: 'Registration',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'inspiration',
      Header: 'Aspiration'
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickDelete={() => removeStudent(id)}
            isNotEdit
            subject={permissionsKeyMap.sponsorship.children.school}
          />
        );
      }
    }
  ];

  const [showStudentAddModal, setShowStudentAddModal] = useState(false);
  const [listOfStudents, setListOfStudents] = useState([]);
  const { where } = useBranchPermission();

  const { organization, selectedBranchId, user } = useAuthContext();
  const navigate = useNavigate();

  const { id } = useParams();

  const { data: schoolData } = useQuery(GET_SCHOOL_BY_ID, {
    variables: {
      id
    }
  });
  const { data: schoolStudentData } = useQuery(GET_SCHOOL_STUDENT_BY_ID, {
    variables: {
      id
    }
  });

  useEffect(() => {
    const tmp = schoolStudentData?.school_students.map(item => item.orphan);
    if (tmp !== undefined && tmp.length !== 0)
      setListOfStudents({
        ...tmp,
        full_name: tmp.first_name + ' ' + tmp.last_name
      });
  }, [schoolStudentData]);

  const removeStudent = id => {
    setListOfStudents(listOfStudents.filter(item => item.id !== id));
  };

  const [addSchoolStudents] = useMutation(ADD_SCHOOL_STUDENTS, {
    onCompleted: () => {
      toast.success('Students Added Successfully');
      navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingSchoolStudents = cache.readQuery({
        query: GET_SCHOOL_STUDENT_BY_ID,
        variables: {
          id
        }
      });
      if (!existingSchoolStudents) return;
      cache.writeQuery({
        query: GET_SCHOOL_STUDENT_BY_ID,
        variables: {
          id
        },
        data: {
          school_students: [...data.insert_school_students.returning]
        }
      });
    }
  });

  const [deleteStudentSchool] = useMutation(DELETE_SCHOOL_STUDENT_BY_SCHOOL);

  const [updateSchool, { loading }] = useMutation(UPDATE_SCHOOL_BY_ID, {
    onCompleted: data => {
      toast.success('School Updated Successfully');

      const tmp = listOfStudents.map(item => {
        return {
          branch_id: selectedBranchId,
          organisation_id: organization.id,
          orphan_id: item.id,
          school_id: data?.update_schools_by_pk.id,
          user_id: user.id
        };
      });
      deleteStudentSchool({ variables: { id } }).then(() => {
        if (tmp.length !== 0)
          addSchoolStudents({ variables: { objects: tmp } });
        else navigate(-1);
      });
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingSchool = cache.readQuery({
        query: GET_SCHOOLS,
        variables: { where }
      });

      if (!existingSchool) return;

      const tmp = existingSchool?.schools.filter(item => item.id !== id);
      cache.writeQuery({
        query: GET_SCHOOLS,
        variables: { where },
        data: {
          schools: [...tmp, data?.update_schools_by_pk]
        }
      });
    }
  });
  const onSubmit = values => {
    updateSchool({
      variables: {
        id,
        country: values.country,
        school_address: values.address,
        school_city: values.city,
        school_email: values.email,
        school_name: values.schoolName,
        serial_number: values.serialNumber,
        school_number: values.contact,
        school_headteacher: values.schoolHeadTeacher
      }
    });
  };

  const submitStudent = value => {
    getBeneficiaryById({
      variables: {
        id: value
      }
    });
  };

  const [getBeneficiaryById, { data: dataBeneficiary }] = useLazyQuery(
    GET_BENEFICIARY_BY_ID
  );

  useEffect(() => {
    if (dataBeneficiary?.orphans_by_pk) {
      setListOfStudents([...listOfStudents, dataBeneficiary?.orphans_by_pk]);
    }
  }, [dataBeneficiary]);

  return (
    <>
      <SchoolForm
        initValues={schoolData?.schools_by_pk}
        onSubmit={onSubmit}
        loading={loading}
      />
      <Row className="mt-3">
        <Col md={12} xxl={12} className="h-md-100">
          {listOfStudents && (
            <CustomAdvanceTable
              data={listOfStudents}
              columns={schoolColumns}
              title="Students"
              addButtonLabel="Add Student"
              addButtonIcon="user"
              onClickAddNew={() => {
                setShowStudentAddModal(true);
              }}
              addNew
              bulkActionsOptions
              handleBulkAction
              selection={true}
              subject={permissionsKeyMap.sponsorship.children.school}
            />
          )}
        </Col>
      </Row>
      <SelectStudentModal
        show={showStudentAddModal}
        onHide={() => setShowStudentAddModal(false)}
        onSubmit={submitStudent}
      />
    </>
  );
}

export default EditSchool;
