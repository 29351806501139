import React from 'react';
import paths from 'routes/paths';
import { useDonorContext } from 'context/donorContext';
import NavBar from 'components/common/profile/sideNavBar';

const donorNavLinks = [
  { id: 1, title: 'Profile', link: paths.donorProfile },
  { id: 2, title: 'Giving', link: paths.donorGiving },
  { id: 3, title: 'Sponsorships', link: paths.donorSponsorships },
  {
    id: 4,
    title: 'Communications',
    link: paths.donorCommunications
  },
  {
    id: 5,
    title: 'Relation Management',
    link: paths.donorRelationManagement
  },
  { id: 6, title: 'Gift Aid', link: paths.donorGiftAid },
  { id: 7, title: 'Feedback', link: paths.donorFeedback },
  {
    id: 8,
    title: 'Add Donation',
    link: paths.addNewDonation,
    isNotRelative: true
  }
];

const DonorNav = () => {
  const { donor } = useDonorContext();
  return <NavBar navLinks={donorNavLinks} user={donor} />;
};

export default DonorNav;
