import paths from './paths';
import { permissionsKeyMap } from './permissions/permissionKeys';

export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  key: permissionsKeyMap.dashboard.parent,
  children: [
    {
      name: 'Dashboard',
      active: true,
      icon: 'chart-pie',
      to: paths.dashboard,
      key: permissionsKeyMap.dashboard.children.dashboard
    }
  ]
};
export const donorRoutes = {
  label: 'Donors',
  labelDisable: true,
  key: permissionsKeyMap.donor.parent,
  children: [
    {
      name: 'Donors',
      active: true,
      icon: 'users',
      to: paths.donors,
      key: permissionsKeyMap.donor.children.donors
    }
  ]
};
export const sponsorshipsRoutes = {
  label: 'WeConnect',
  labelDisable: true,
  key: permissionsKeyMap.sponsorship.parent,
  children: [
    {
      key: permissionsKeyMap.sponsorship.parent,
      name: 'WeConnect',
      active: true,
      icon: 'hands-helping',
      children: [
        {
          name: 'All Beneficiaries',
          to: paths.beneficiaries,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.beneficiaries
        },
        {
          name: 'Sponsored List',
          to: paths.sponsoredList,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.sponsoredList
        },
        {
          name: 'Unsponsored list',
          to: paths.unsponsoredList,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.unsponsoredList
        },
        {
          name: 'Sponsorship Settings',
          to: paths.sponsorshipValues,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.sponsorshipValues
        },
        {
          name: 'School',
          to: paths.school,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.school
        },
        {
          name: 'Mosque',
          to: paths.mosque,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.mosque
        },
        {
          name: 'Hospital',
          to: paths.hospital,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.hospital
        },
        {
          name: 'Orphanage',
          to: paths.orphanage,
          exact: true,
          active: true,
          key: permissionsKeyMap.sponsorship.children.orphanage
        }
      ]
    }
  ]
};
export const projectRoutes = {
  label: 'Projects',
  labelDisable: true,
  key: permissionsKeyMap.project.parent,
  children: [
    {
      name: 'Projects',
      active: true,
      icon: 'project-diagram',
      key: permissionsKeyMap.project.parent,
      children: [
        {
          name: 'Kanban',
          to: paths.projectsKanban,
          exact: true,
          active: true,
          key: permissionsKeyMap.project.children.projects
        },
        {
          name: 'Project List',
          to: paths.projectsList,
          exact: true,
          active: true,
          key: permissionsKeyMap.project.children.projects
        }
      ]
    }
  ]
};
export const donationsRoutes = {
  label: 'Donations',
  labelDisable: true,
  key: permissionsKeyMap.donation.parent,
  children: [
    {
      name: 'Donations',
      active: true,
      icon: 'file-invoice-dollar',
      key: permissionsKeyMap.donation.parent,
      children: [
        {
          name: 'Donation Settings',
          to: '/donations/settings',
          exact: true,
          active: true,
          key: permissionsKeyMap.donation.children.donationSettings
        },
        {
          name: 'Donation Packages',
          to: '/donations/packages',
          exact: true,
          active: true,
          key: permissionsKeyMap.donation.children.donationPackages
        },
        {
          name: 'Urgent Appeals',
          to: '/donations/urgent-appeals',
          exact: true,
          active: true,
          key: permissionsKeyMap.donation.children.urgentAppeals
        }
      ]
    }
  ]
};
export const aidRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  key: permissionsKeyMap.giftAid.parent,
  children: [
    {
      name: 'Gift Aid',
      active: true,
      icon: 'plus',
      to: paths.giftAid,
      key: permissionsKeyMap.giftAid.children.giftAid
    }
  ]
};
export const staffRoutes = {
  label: 'Staff',
  labelDisable: true,
  children: [
    {
      name: 'Staff',
      active: false,
      disable: true,
      icon: 'users',
      to: '/staff'
    }
  ]
};
export const reportsRoutes = {
  label: 'Reports',
  labelDisable: true,
  children: [
    {
      name: 'Reports',
      active: false,
      disable: true,
      icon: 'user-edit',
      to: '/reports'
    }
  ]
};
export const kanbanProjectRoutes = {
  label: 'Main Projects',
  children: [
    {
      name: 'Main Dashboard',
      icon: ['fab', 'trello'],
      to: '#',
      active: true
    },
    {
      name: 'All',
      icon: ['fab', 'trello'],
      to: '#',
      active: true
    },
    {
      name: 'Somalia',
      icon: 'graduation-cap',
      active: true,
      children: [
        {
          name: 'City 1',
          to: '#',
          active: true
        },
        {
          name: 'City 2',
          to: '#',
          active: true
        },
        {
          name: 'City 3',
          to: '#',
          active: true
        }
      ]
    },
    {
      name: 'Kenya',
      icon: 'graduation-cap',
      active: true,
      children: [
        {
          name: 'City 1',
          to: '#',
          active: true
        },
        {
          name: 'City 2',
          to: '#',
          active: true
        },
        {
          name: 'City 3',
          to: '#',
          active: true
        }
      ]
    },

    {
      name: 'uk',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'City 1',
          to: '#',
          active: true
        }
      ]
    },
    {
      name: 'Ethiopia',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'City 1',
          to: '#',
          active: true
        },
        {
          name: 'City 2',
          to: '#',
          active: true
        },
        {
          name: 'City 3',
          to: '#',
          active: true
        }
      ]
    },
    {
      name: 'Yemen',
      icon: 'share-alt',
      active: true,
      children: [
        {
          name: 'City 1',
          to: '#',
          active: true
        },
        {
          name: 'City 2',
          to: '#',
          active: true
        },
        {
          name: 'City 3',
          to: '#',
          active: true
        }
      ]
    }
  ]
};
export const settingsRoutes = {
  label: 'Settings',
  labelDisable: true,
  key: permissionsKeyMap.settings.parent,
  children: [
    {
      name: 'Settings',
      icon: 'cog',
      active: true,
      key: permissionsKeyMap.settings.parent,
      children: [
        {
          name: 'Personal Profile',
          to: paths.personalSettings,
          active: true,
          key: permissionsKeyMap.settings.children.personal
        },
        // {
        //   name: 'Branches Details',
        //   to: paths.branchesSetting,
        //   active: true,
        //   key: permissionsKeyMap.settings.children.branches
        // },
        {
          name: 'Change Branch',
          to: paths.branchSelection,
          active: true,
          key: permissionsKeyMap.settings.children.smtp
        }
      ]
    }
  ]
};
export const eventSchedulerRoutes = {
  label: 'Event Scheduler',
  labelDisable: true,
  key: permissionsKeyMap.eventScheduler.parent,
  children: [
    {
      name: 'Event Scheduler',
      active: true,
      icon: 'calendar-day',
      to: paths.eventSchedule,
      key: permissionsKeyMap.eventScheduler.children.eventScheduler
    }
  ]
};
export const organizationSettingRoutes = {
  // label: 'Settings',
  labelDisable: true,
  key: 'organization_route',
  children: [
    {
      name: 'Settings',
      icon: 'cog',
      active: true,
      // key: permissionsKeyMap.settings.parent,
      children: [
        {
          name: 'Organization Details',
          to: paths.organizationSettings,
          active: true,
          key: permissionsKeyMap.settings.children.organizationDetails
        },
        {
          name: 'Branches ',
          to: paths.branchesSetting,
          active: true,
          key: permissionsKeyMap.settings.children.branches
        },
        {
          name: 'Users & Roles',
          to: paths.userRolesSetting,
          active: true,
          key: permissionsKeyMap.settings.children.users
        },
        {
          name: 'Payment Integration',
          to: paths.paymentSettings,
          active: true,
          key: permissionsKeyMap.settings.children.payment
        },
        {
          name: 'SMTP',
          to: paths.smtpSettings,
          active: true,
          key: permissionsKeyMap.settings.children.smtp
        }
      ]
    }
  ]
};

// export const organizationBranchRoutes = {
//   label: 'Settings',
//   children: [
//     {
//       name: 'Settings',
//       icon: 'cog',
//       active: true,
//       children: [
//         {
//           name: 'Organization',
//           to: paths.editOrganization,
//           active: true
//         }
//       ]
//     }
//   ]
// };

export default [
  dashboardRoutes,
  donorRoutes,
  sponsorshipsRoutes,
  projectRoutes,
  donationsRoutes,
  aidRoutes,
  // staffRoutes,
  // reportsRoutes,
  settingsRoutes,
  eventSchedulerRoutes
  // organizationSettingRoutes
  // organizationBranchRoutes
];
