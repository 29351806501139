import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_CONDITION = gql`
  mutation CreateSponsorshipCondition(
    $condition: String!
    $organisation_id: uuid!
  ) {
    insert_sponsorship_condition_one(
      object: { condition: $condition, organisation_id: $organisation_id }
    ) {
      id
      organisation_id
      condition
      created_at
    }
  }
`;

const UPDATE_CONDITION = gql`
  mutation UpdateSponsorshipCondition($id: uuid!, $condition: String!) {
    update_sponsorship_condition_by_pk(
      pk_columns: { id: $id }
      _set: { condition: $condition }
    ) {
      id
      organisation_id
      condition
      created_at
    }
  }
`;

const DELETE_CONDITION = gql`
  mutation DeleteSponsorshipCondition($id: uuid!) {
    delete_sponsorship_condition_by_pk(id: $id) {
      id
    }
  }
`;

const GET_CONDITIONS = gql`
  query GetSponsorshipCondition($where: sponsorship_condition_bool_exp!) {
    sponsorship_condition(where: $where) {
      id
      organisation_id
      condition
      created_at
    }
  }
`;
/* -------------------------------------------------------------------------- */
function Conditions({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();

  const { organization } = useContext(AuthContext);
  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);
  const [createCondition, { loading: createLoading }] = useMutation(
    CREATE_CONDITION,
    {
      refetchQueries: ['GetSponsorshipCondition'],
      onCompleted: () => {
        toast.success('Condition created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateCondition, { loading: updateLoading }] = useMutation(
    UPDATE_CONDITION,
    {
      refetchQueries: ['GetSponsorshipCondition'],
      onCompleted: () => {
        toast.success('Condition updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteCondition, { loading: deleteLoading }] = useMutation(
    DELETE_CONDITION,
    {
      refetchQueries: ['GetSponsorshipCondition'],
      onCompleted: () => {
        toast.success('Condition deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getConditions, { data }] = useLazyQuery(GET_CONDITIONS);

  useEffect(() => {
    getConditions({ variables: { ...where } });
  }, [where]);

  const handleCreate = ({ condition }) => {
    createCondition({
      variables: {
        condition,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateCondition({
      variables: {
        id,
        condition: data.condition
      }
    });
  };
  const handleDelete = id => {
    deleteCondition({
      variables: {
        id
      }
    });
  };
  const list = data?.sponsorship_condition.map(item => ({
    id: item.id,
    values: [
      {
        value: item.condition,
        name: 'condition'
      }
    ]
  }));
  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Condition'}
      listTitle={'Current Condition'}
      title={'Sponsorship Conditions'}
      inputs={[
        {
          name: 'condition',
          placeholder: 'Add Condition',
          type: 'text',
          required: true,
          value: '',
          validation: yup.string().required('Condition is required')
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

Conditions.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default Conditions;
