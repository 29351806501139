import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';

import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import FalconCardHeader from 'components/common/FalconCardHeader';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    formatter: '<strong>{b0}</strong> : {c0}'
  },
  xAxis: {
    data: ['Week 4', 'Week 5', 'week 6', 'week 7']
  },
  series: [
    {
      type: 'line',
      data,
      smooth: true,
      lineStyle: {
        width: 3
      },

      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: {
    bottom: '2%',
    top: '2%',
    right: '10px',
    left: '10px'
  }
});

const TotalOrder = ({
  data,
  title = 'Total Order',
  amount = '58.4K',
  changes = 13.6
}) => {
  return (
    <Card className="h-md-100">
      <FalconCardHeader
        title={title}
        light={false}
        titleTag="h6"
        className="pb-0"
        // endEl={<CardDropdown />}
      />

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1">{amount}</h2>
          <Badge
            pill
            bg="200"
            className={`text-${changes > 5 ? 'primary' : 'warning'}  fs--2`}
          >
            <FontAwesomeIcon
              icon={`caret-${changes > 5 ? 'up' : 'down'}`}
              className="me-1"
            />
            {changes}%
          </Badge>
        </div>
        <div className="ps-0">
          <BasicECharts
            echarts={echarts}
            options={getOptions(data)}
            style={{ width: '8.5rem', height: 90 }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

TotalOrder.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string,
  amount: PropTypes.string,
  changes: PropTypes.number
};

export default TotalOrder;
