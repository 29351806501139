import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ProfileDonorInfo = ({ donorData }) => {
  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={<h4 className="fw-bold">Donor information</h4>}
        light={false}
        titleTag="div"
        className="pb-1"
        menuClassName="align-items-start"
        endEl={
          <IconButton
            icon="pen"
            variant="falcon-default"
            transform="shrink-3"
            className="fs--1 bg-transparent shadow-none opacity-25 d-none"
          >
            Quick Update
          </IconButton>
        }
      />
      <Card.Body>
        <Row>
          <Col>
            <Flex>
              <div className="donor-info-title">
                <p className="mb-2">Title</p>
                <p className="mb-0">Name</p>
              </div>
              <div>
                <p className="mb-2">{donorData?.donars_by_pk?.title}</p>
                <p className="text-primary h6">
                  {donorData?.donars_by_pk?.first_name +
                    ' ' +
                    donorData?.donars_by_pk?.last_name}
                </p>
              </div>
            </Flex>
            <hr />
            <Flex>
              <div className="donor-info-title">
                <p>Address</p>
              </div>
              <div>
                <p className="mb-2">
                  {donorData?.donars_by_pk?.address.length
                    ? donorData?.donars_by_pk?.address[0].addressLine1 +
                      ', ' +
                      donorData?.donars_by_pk?.address[0].addressLine2
                    : ''}
                </p>
                <p className="mb-0">
                  {' '}
                  {donorData?.donars_by_pk?.address.length
                    ? donorData?.donars_by_pk?.address[0].city +
                      ', ' +
                      donorData?.donars_by_pk?.address[0].country +
                      ', ' +
                      donorData?.donars_by_pk?.address[0].postcodes
                    : ''}
                </p>
              </div>
            </Flex>
            <hr />
            <Flex>
              <div className="donor-info-title">
                <p>Phone No.</p>
              </div>
              <div>
                {donorData?.donars_by_pk?.phone.length
                  ? donorData?.donars_by_pk?.phone.map((phone, index) => {
                      return (
                        <Flex key={index}>
                          <p className="mb-2">{'+' + phone.phoneNumber}</p>
                          <Form.Group
                            className="ms-3"
                            controlId={'phone-control-1'}
                          >
                            <Flex>
                              <Form.Check
                                inline
                                type="radio"
                                name="phone"
                                id={'phone-control-1'}
                                value={'phone'}
                                checked={phone.isPrimary}
                                disabled
                                className="me-2"
                              />
                              <Form.Label className="mb-0">Primary</Form.Label>
                            </Flex>
                          </Form.Group>
                        </Flex>
                      );
                    })
                  : ''}
              </div>
            </Flex>
            <hr />
            <Flex>
              <div className="donor-info-title">
                <p>E-Mail</p>
              </div>
              <div>
                {donorData?.donars_by_pk?.email.length
                  ? donorData?.donars_by_pk?.email.map((email, index) => {
                      return (
                        <Flex key={index}>
                          <p className="mb-2">{email.email}</p>
                          <Form.Group
                            className="ms-3"
                            controlId={'phone-control-1'}
                          >
                            <Flex>
                              <Form.Check
                                inline
                                type="radio"
                                name="phone"
                                id={'phone-control-1'}
                                value={'phone'}
                                checked={email.isPrimary}
                                disabled
                                className="me-2"
                              />
                              <Form.Label className="mb-0">Primary</Form.Label>
                            </Flex>
                          </Form.Group>
                        </Flex>
                      );
                    })
                  : ''}
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

ProfileDonorInfo.propTypes = {
  donorData: PropTypes.object
};
export default ProfileDonorInfo;
