import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

export const FEE_TYPE_PERCENTAGE = 'percentage';
export const FEE_TYPE_FIXED = 'fixed';

const feeTypeOptions = [
  {
    value: FEE_TYPE_FIXED,
    label: 'Fixed'
  },
  {
    value: FEE_TYPE_PERCENTAGE,
    label: 'Percentage'
  }
];

/* -------------------------------------------------------------------------- */
const CREATE_TRANSACTION_FEE = gql`
  mutation CreateTransactionFee(
    $amount: Int = ""
    $organisation_id: uuid = ""
    $fee_type: String = ""
  ) {
    insert_transaction_fee_one(
      object: {
        amount: $amount
        fee_type: $fee_type
        organisation_id: $organisation_id
      }
    ) {
      id
      amount
      fee_type
      organisation_id
    }
  }
`;

const UPDATE_TRANSACTION_FEE = gql`
  mutation UpdateTransactionFee(
    $id: uuid = ""
    $amount: Int = ""
    $fee_type: String = ""
  ) {
    update_transaction_fee(
      where: { id: { _eq: $id } }
      _set: { amount: $amount, fee_type: $fee_type }
    ) {
      returning {
        id
        amount
        fee_type
        organisation_id
      }
    }
  }
`;

const DELETE_TRANSACTION_FEE = gql`
  mutation DeleteTransactionFee($id: uuid = "") {
    delete_transaction_fee_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_TRANSACTION_FEES = gql`
  query GetTransactionFees($where: transaction_fee_bool_exp = {}) {
    transaction_fee(order_by: { created_at: desc }, where: $where) {
      id
      amount
      fee_type
      organisation_id
    }
  }
`;

/* -------------------------------------------------------------------------- */
function TransactionFees({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);

  const [createTransactionFee, { loading: createLoading }] = useMutation(
    CREATE_TRANSACTION_FEE,
    {
      refetchQueries: ['GetTransactionFees'],
      onCompleted: () => {
        toast.success('Transaction Fee created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateTransactionFee, { loading: updateLoading }] = useMutation(
    UPDATE_TRANSACTION_FEE,
    {
      refetchQueries: ['GetTransactionFees'],
      onCompleted: () => {
        toast.success('Transaction Fee updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteTransactionFee, { loading: deleteLoading }] = useMutation(
    DELETE_TRANSACTION_FEE,
    {
      refetchQueries: ['GetTransactionFees'],
      onCompleted: () => {
        toast.success('Transaction Fee deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getTransactionFees, { data }] = useLazyQuery(GET_TRANSACTION_FEES);

  useEffect(() => {
    if (Object.keys(where).length)
      getTransactionFees({ variables: { ...where } });
  }, [where]);

  const handleCreate = ({ amount, fee_type }) => {
    createTransactionFee({
      variables: {
        amount,
        fee_type,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateTransactionFee({
      variables: {
        id,
        amount: data.amount,
        fee_type: data.fee_type
      }
    });
  };
  const handleDelete = id => {
    deleteTransactionFee({
      variables: {
        id
      }
    });
  };
  const list = data?.transaction_fee.map(fee => ({
    id: fee.id,
    values: [
      {
        value: fee.amount,
        display: `${fee.amount} ${fee.fee_type === 'percentage' ? '%' : 'USD'}`,
        name: 'amount'
      },
      {
        value: fee.fee_type,
        display: feeTypeOptions.find(option => option.value === fee.fee_type)
          .label,
        name: 'fee_type'
      }
    ]
  }));
  // const handleOpenChange = isOpen => {
  //   setIsOpen(isOpen);
  // };
  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      // formTitle={'Add Transaction Fees'}
      listTitle={'Current Transaction Fees'}
      title={'Transaction Fees'}
      inputs={[
        {
          name: 'amount',
          placeholder: 'Add Transaction Fee',
          type: 'text',
          required: true,
          value: '',
          validation: yup
            .number()
            .typeError('Transaction Fee must be a number')
            .required('Transaction Fee is required'),
          label: 'Transaction Fee Amount'
        },
        {
          name: 'fee_type',
          placeholder: 'Add Fee Type',
          type: 'select',
          required: true,
          value: '',
          validation: yup.string().required('Fee Type is required'),
          label: 'Fee Type',
          options: feeTypeOptions
        }
      ]}
      list={list}
      subject={subject}
      // hasOpenCheck
      // handleOpenChange={handleOpenChange}
      // isOpen={isOpen}
    />
  );
}

TransactionFees.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default TransactionFees;
