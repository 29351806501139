import AppContext from 'context/Context';
import useParentPermissions from 'hooks/useParentPermissions';
import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { Can } from 'routes/permissions/Can';
import { userActions } from 'routes/permissions/permissionKeys';
import routes from 'routes/routes';
import NavbarDropdown from './NavbarDropdown';
import NavbarNavLink from './NavbarNavLink';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const NavbarTopDropDownMenus = ({ width }) => {
  const {
    config: { navbarCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);
  const { shouldShowParent } = useParentPermissions();
  const handleDropdownItemClick = () => {
    if (navbarCollapsed) {
      setConfig('navbarCollapsed', !navbarCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };

  const filteredRoutes = routes.filter(route => shouldShowParent(route.key));
  return (
    <Card className="mb-3 position-fixed" style={{ zIndex: '1', width: width }}>
      <Card.Body
        className="d-flex align-items-center"
        style={{
          paddingTop: '0px',
          paddingBottom: '0px'
        }}
      >
        {filteredRoutes.map(route =>
          route.children[0]?.children ? (
            <div
              key={route.children[0].name}
              className={classNames('py-3 px-2 navbar-button', {
                'navbar-button-black':
                  route.children[0].children.filter(
                    item => item.to === window.location.pathname
                  ).length !== 0
              })}
            >
              <NavbarDropdown title={route.label}>
                {route.children[0].children.map(childRoute => (
                  <Can
                    key={childRoute.name}
                    I={userActions.read}
                    a={childRoute.key}
                  >
                    <NavbarNavLink
                      // key={childRoute.name}
                      route={childRoute}
                      onClick={handleDropdownItemClick}
                    >
                      {childRoute.name}
                    </NavbarNavLink>
                  </Can>
                ))}
              </NavbarDropdown>
            </div>
          ) : (
            <div
              key={route.children[0].name}
              className={classNames('py-3 px-2 navbar-button', {
                'navbar-button-black': window.location.pathname.includes(
                  route.children[0].to
                )
              })}
            >
              <NavbarNavLink
                route={route.children[0]}
                onClick={handleDropdownItemClick}
                title={route.children[0].name}
              />
            </div>
          )
        )}
      </Card.Body>
    </Card>
  );
};

NavbarTopDropDownMenus.propTypes = {
  width: PropTypes.number
};
export default NavbarTopDropDownMenus;
