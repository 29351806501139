import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import {
  Accordion,
  AccordionContext,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  useAccordionButton
} from 'react-bootstrap';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';

const accessOptions = [
  { value: '0', label: 'None' },
  { value: '1', label: 'All' },
  { value: '2', label: 'Admin Only' },
  { value: '3', label: 'Read Only' },
  { value: '4', label: 'Edit Only' }
];

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  const [value, setValue] = useState(null);

  return (
    <Flex justifyContent={'between'}>
      <IconButton
        type="button"
        variant="btn-info"
        className={`bg-transparent border-0 shadow-none px-0 text-${
          isCurrentEventKey ? 'primary' : '900'
        }`}
        onClick={decoratedOnClick}
        icon={isCurrentEventKey ? 'caret-up' : 'caret-down'}
        iconAlign="left"
        transform="shrink-3"
      >
        {children}
      </IconButton>
      <ReactSelect
        className="w-50 w-md-25"
        closeMenuOnSelect={true}
        options={accessOptions}
        placeholder="Select..."
        classNamePrefix="react-select"
        value={value}
        onChange={value => setValue(value)}
      />
    </Flex>
  );
}

const ItemList = ({ title, options }) => {
  const [value, setValue] = useState(null);

  return (
    <ListGroup.Item className="border-0 px-0">
      <Flex justifyContent="between" alignItems="center">
        <p className="mb-0 ps-2">
          <FontAwesomeIcon
            icon="circle"
            style={{
              fontSize: 6,
              marginBottom: 3,
              marginRight: 8
            }}
          />
          {title}
        </p>
        <ReactSelect
          className="w-50 w-md-25"
          closeMenuOnSelect={true}
          options={options}
          placeholder="Select..."
          classNamePrefix="react-select"
          value={value}
          onChange={value => setValue(value)}
        />
      </Flex>
    </ListGroup.Item>
  );
};

const ContactPreference = () => {
  const [date, setDate] = useState(null);
  const emailOptions = [
    { id: 1, title: 'New Campaign', access: accessOptions },
    { id: 2, title: 'Donation Reminders', access: accessOptions }
  ];
  return (
    <Card className="mb-3">
      <Card.Header className="pb-0">
        <Card.Title className="fw-medium">Contact Preference</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Accordion defaultActiveKey="0">
              <Card className="shadow-none">
                <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                  <ContextAwareToggle eventKey="0">Email</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body className="p-0 pe-md-3">
                    <ListGroup>
                      {emailOptions?.map(option => (
                        <ItemList
                          key={option.id}
                          title={option.title}
                          options={option.access}
                        />
                      ))}
                    </ListGroup>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <hr />
              <Card className="shadow-none">
                <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                  <ContextAwareToggle eventKey="1">Post</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>Content will be here</Card.Body>
                </Accordion.Collapse>
              </Card>
              <hr />
              <Card className="shadow-none">
                <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                  <ContextAwareToggle eventKey="2">SMS</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>Content will be here</Card.Body>
                </Accordion.Collapse>
              </Card>
              <hr />
              <Card className="shadow-none">
                <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                  <ContextAwareToggle eventKey="3">
                    Telephone
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>Content will be here</Card.Body>
                </Accordion.Collapse>
              </Card>
              <hr />
            </Accordion>
            <Row>
              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="Consented_Date"
              >
                <Form.Label>Consented Date</Form.Label>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  className="form-control"
                  placeholderText="DD-MM-YYYY"
                  timeIntervals={5}
                  dateFormat="dd-MM-yyyy"
                  fixedHeight
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="4"
                className="mb-3"
                controlId="Consented_Via"
              >
                <Form.Label>Consented Via</Form.Label>
                <Form.Control type="text" placeholder="" defaultValue="" />
                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={12} controlId="remark">
                <Form.Label>Remark</Form.Label>
                <Form.Control as="textarea" rows={5} />
              </Form.Group>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      <Flex className="p-3 pt-0" justifyContent={'between'}>
        <Button
          variant="light"
          className="bg-transparent border-0 fs--1 fs-md-0 shadow-none text-primary p-1"
        >
          + Add More Field(s)
        </Button>

        <Button
          as={Link}
          to="/"
          target="_blank"
          variant="link"
          size="sm"
          className="ps-0"
        >
          <FontAwesomeIcon icon="exclamation-circle" className="me-2 fs--2" />
          <span className="text-900">Read Privacy Statement</span>
        </Button>
      </Flex>
    </Card>
  );
};

ContextAwareToggle.propTypes = {
  children: PropTypes.node.isRequired,
  callback: PropTypes.func,
  eventKey: PropTypes.string
};

ItemList.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string
};

export default ContactPreference;
