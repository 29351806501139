import { BeneficiaryContextProvider } from 'context/beneficiaryContext';
import { getRelativePath } from 'helpers/utils';
import { Col, Row } from 'react-bootstrap';
import { Navigate, Route, Routes } from 'react-router-dom';
import paths from 'routes/paths';
import BeneficiaryNav from './BeneficiaryNav';
import BeneficiaryProfile from './BeneficiaryProfile';
import Donors from './donor';
import EditDonor from './donor/EditDonor';
import Gellery from './gallery';
import MedicalReport from './medical';
import BeneficiaryMosque from './mosque/BeneficiaryMosque';
import School from './school';
import AddSchool from './school/AddSchool';
import CreateSponsorship from './sponsorships/CreateSponsorship';
import BeneficiaryHospital from './hospital/BeneficiaryHospital';
import BeneficiaryOrphanage from './orphanage/BeneficiaryOrphanage';

const BeneficiaryLayout = () => {
  console.log('test');
  return (
    <BeneficiaryContextProvider>
      <div className="py-0">
        <Row className="g-3">
          <Col lg={12}>
            <BeneficiaryNav />
          </Col>
          <Col lg={12}>
            <Routes>
              <Route
                path={getRelativePath(
                  paths.beneficiaryProfile,
                  paths.beneficiaryDetails
                )}
                element={<BeneficiaryProfile />}
              />

              <Route
                path={getRelativePath(
                  paths.beneficiaryMedical,
                  paths.beneficiaryDetails
                )}
                element={<MedicalReport />}
              />
              <Route
                path={getRelativePath(
                  paths.beneficiaryCreateNewSponsorships,
                  paths.beneficiaryDetails
                )}
                element={<CreateSponsorship />}
              />
              <Route
                path={getRelativePath(
                  paths.beneficiaryDonors,
                  paths.beneficiaryDetails
                )}
                element={<Donors />}
              />

              <Route
                path={getRelativePath(
                  paths.beneficiaryDonorEdit,
                  paths.beneficiaryDetails
                )}
                element={<EditDonor />}
              />

              <Route
                path={getRelativePath(
                  paths.beneficiaryShool,
                  paths.beneficiaryDetails
                )}
                element={<School />}
              />
              <Route
                path={getRelativePath(
                  paths.beneficiaryMosque,
                  paths.beneficiaryDetails
                )}
                element={<BeneficiaryMosque />}
              />
              <Route
                path={getRelativePath(
                  paths.beneficiaryHospital,
                  paths.beneficiaryDetails
                )}
                element={<BeneficiaryHospital />}
              />
              <Route
                path={getRelativePath(
                  paths.beneficiaryOrphanage,
                  paths.beneficiaryDetails
                )}
                element={<BeneficiaryOrphanage />}
              />
              <Route
                path={getRelativePath(
                  paths.beneficiaryAddSchool,
                  paths.beneficiaryDetails
                )}
                element={<AddSchool />}
              />

              <Route
                path={getRelativePath(
                  paths.beneficiaryGallery,
                  paths.beneficiaryDetails
                )}
                element={<Gellery />}
              />

              <Route
                path="*"
                element={
                  <Navigate
                    to={getRelativePath(paths.donorProfile, paths.donorDetails)}
                    replace
                  />
                }
              />
            </Routes>
          </Col>
        </Row>
      </div>
    </BeneficiaryContextProvider>
  );
};

export default BeneficiaryLayout;
