import { getRelativePath, parseRouteParams } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Nav } from 'react-bootstrap';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routes/paths';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import IconButton from '../IconButton';

const NavBar = ({ navLinks }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('donorId');

  return (
    <>
      <Card className="mb-3">
        <Card.Body className="p-3">
          <Nav className="flex-row" style={{ justifyContent: 'space-between' }}>
            <div
              className="d-sm-inline-flex d-md-inline-flex d-xl-flex"
              style={{
                flexFlow: 'wrap'

                // marginRight: '10vw'
              }}
            >
              {navLinks.map(({ id, link, isNotRelative, title }) => (
                <Nav.Item key={id} className="nav-pills">
                  <Nav.Link
                    className="fs--1 fw-medium"
                    as={NavLink}
                    to={
                      isNotRelative
                        ? link
                        : getRelativePath(link, paths.donorDetails)
                    }
                  >
                    {title}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </div>
            <Can
              I={userActions.create}
              a={permissionsKeyMap.donation.children.donations}
            >
              <IconButton
                className="d-block"
                style={{
                  // position: 'absolute',
                  height: 'fit-content',
                  right: 0,
                  marginRight: '21px'
                }}
                onClick={() =>
                  navigate(
                    parseRouteParams(
                      paths.configNewDonation,
                      {},
                      {
                        donorId: id
                      }
                    )
                  )
                }
              >
                <span className="d-block d-md-inline-block ms-2">
                  Add Donation
                </span>
              </IconButton>
            </Can>
          </Nav>
        </Card.Body>
      </Card>
    </>
  );
};

NavBar.propTypes = {
  navLinks: PropTypes.array,
  user: PropTypes.object,
  score: PropTypes.bool,
  type: PropTypes.string
};
export default NavBar;
