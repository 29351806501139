import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { RHFSelect } from 'components/common/form';
import PropTypes from 'prop-types';

const locationConditionOptions = [
  {
    label: 'Good',
    value: 'good'
  },
  {
    label: 'Bad',
    value: 'bad'
  }
];

export const LocationCard = ({
  sectionRef,
  sideNavLink,
  countriesOptions,
  citiesOptions
}) => {
  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Location</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3 gy-3">
          <Col xs={12} sm={6} md={4} className="pt-3">
            <RHFSelect
              name="location.country"
              label="Country"
              required
              options={countriesOptions}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="pt-3">
            <RHFSelect
              name="location.city"
              label="City"
              required
              options={citiesOptions}
              isDisabled={citiesOptions.length === 0}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="pt-3">
            <RHFSelect
              name="location.condition"
              label="Location Condition"
              required
              options={locationConditionOptions}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

LocationCard.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string,
  countriesOptions: PropTypes.array,
  citiesOptions: PropTypes.array
};
