import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import { RHFCheck, RHFControl } from 'components/common/form';

const AddressInputs = ({
  addNewAddress,
  deleteAddress,
  setValue,
  addresses
}) => {
  return (
    <Card className="mb-3">
      {addresses.map((address, index) => (
        <Card key={`address-${index}`} className="shadow-none">
          <Card.Header>
            <Card.Title className="fw-medium d-flex justify-content-between align-items-center">
              <span>Donor #{index + 1} Address</span>
              {index !== 0 && (
                <IconButton
                  variant="light"
                  icon="trash"
                  className={classNames(
                    'mb-1 bg-transparent border-0 shadow-none',
                    { 'v-hidden': address.defaultAddress }
                  )}
                  iconAlign="left"
                  transform="shrink-3"
                  iconClassName="text-danger me-2"
                  onClick={() => deleteAddress(index)}
                  disabled={address.defaultAddress}
                >
                  Delete
                </IconButton>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Row className="g-3">
              <Col md={12}>
                <Form.Label className="text-600">Country</Form.Label>
                <Row>
                  <Col md="6" controlId="title">
                    <ReactFlagsSelect
                      selected={address.country}
                      onSelect={code =>
                        setValue(`addresses.${index}.country`, code)
                      }
                      className="react-select"
                      countries={Object.keys({
                        AF: 'Afghanistan',
                        AL: 'Albania',
                        DZ: 'Algeria',
                        AS: 'American Samoa',
                        AD: 'Andorra',
                        AO: 'Angola',
                        AI: 'Anguilla',
                        AG: 'Antigua and Barbuda',
                        AR: 'Argentina',
                        AM: 'Armenia',
                        AW: 'Aruba',
                        AU: 'Australia',
                        AT: 'Austria',
                        AZ: 'Azerbaijan',
                        BS: 'Bahamas',
                        BH: 'Bahrain',
                        BD: 'Bangladesh',
                        BB: 'Barbados',
                        BY: 'Belarus',
                        BE: 'Belgium',
                        BZ: 'Belize',
                        BJ: 'Benin',
                        BM: 'Bermuda',
                        BT: 'Bhutan',
                        BO: 'Bolivia, Plurinational State of',
                        BA: 'Bosnia and Herzegovina',
                        BW: 'Botswana',
                        BR: 'Brazil',
                        IO: 'British Indian Ocean Territory',
                        BG: 'Bulgaria',
                        BF: 'Burkina Faso',
                        BI: 'Burundi',
                        KH: 'Cambodia',
                        CM: 'Cameroon',
                        CA: 'Canada',
                        CV: 'Cape Verde',
                        KY: 'Cayman Islands',
                        CF: 'Central African Republic',
                        TD: 'Chad',
                        CL: 'Chile',
                        CN: 'China',
                        CO: 'Colombia',
                        KM: 'Comoros',
                        CG: 'Congo',
                        CD: 'Democratic Republic of the Congo',
                        CK: 'Cook Islands',
                        CR: 'Costa Rica',
                        CI: "Côte d'Ivoire",
                        HR: 'Croatia',
                        CU: 'Cuba',
                        CW: 'Curaçao',
                        CY: 'Cyprus',
                        CZ: 'Czech Republic',
                        DK: 'Denmark',
                        DJ: 'Djibouti',
                        DM: 'Dominica',
                        DO: 'Dominican Republic',
                        EC: 'Ecuador',
                        EG: 'Egypt',
                        SV: 'El Salvador',
                        GQ: 'Equatorial Guinea',
                        ER: 'Eritrea',
                        EE: 'Estonia',
                        ET: 'Ethiopia',
                        FK: 'Falkland Islands (Malvinas)',
                        FO: 'Faroe Islands',
                        FJ: 'Fiji',
                        FI: 'Finland',
                        FR: 'France',
                        PF: 'French Polynesia',
                        GA: 'Gabon',
                        GM: 'Gambia',
                        GE: 'Georgia',
                        DE: 'Germany',
                        GH: 'Ghana',
                        GI: 'Gibraltar',
                        GR: 'Greece',
                        GL: 'Greenland',
                        GD: 'Grenada',
                        GU: 'Guam',
                        GT: 'Guatemala',
                        GG: 'Guernsey',
                        GN: 'Guinea',
                        GW: 'Guinea-Bissau',
                        HT: 'Haiti',
                        HN: 'Honduras',
                        HK: 'Hong Kong',
                        HU: 'Hungary',
                        IS: 'Iceland',
                        IN: 'India',
                        ID: 'Indonesia',
                        IR: 'Iran, Islamic Republic of',
                        IQ: 'Iraq',
                        IE: 'Ireland',
                        IM: 'Isle of Man',
                        IL: 'Israel',
                        IT: 'Italy',
                        JM: 'Jamaica',
                        JP: 'Japan',
                        JE: 'Jersey',
                        JO: 'Jordan',
                        KZ: 'Kazakhstan',
                        KE: 'Kenya',
                        KI: 'Kiribati',
                        KP: 'North Korea',
                        KR: 'South Korea',
                        KW: 'Kuwait',
                        KG: 'Kyrgyzstan',
                        LA: "Lao People's Democratic Republic",
                        LV: 'Latvia',
                        LB: 'Lebanon',
                        LS: 'Lesotho',
                        LR: 'Liberia',
                        LY: 'Libya',
                        LI: 'Liechtenstein',
                        LT: 'Lithuania',
                        LU: 'Luxembourg',
                        MO: 'Macao',
                        MK: 'Republic of Macedonia',
                        MG: 'Madagascar',
                        MW: 'Malawi',
                        MY: 'Malaysia',
                        MV: 'Maldives',
                        ML: 'Mali',
                        MT: 'Malta',
                        MH: 'Marshall Islands',
                        MQ: 'Martinique',
                        MR: 'Mauritania',
                        MU: 'Mauritius',
                        MX: 'Mexico',
                        FM: 'Micronesia, Federated States of',
                        MD: 'Republic of Moldova',
                        MC: 'Monaco',
                        MN: 'Mongolia',
                        ME: 'Montenegro',
                        MS: 'Montserrat',
                        MA: 'Morocco',
                        MZ: 'Mozambique',
                        MM: 'Myanmar',
                        NA: 'Namibia',
                        NR: 'Nauru',
                        NP: 'Nepal',
                        NL: 'Netherlands',
                        NZ: 'New Zealand',
                        NI: 'Nicaragua',
                        NE: 'Niger',
                        NG: 'Nigeria',
                        NU: 'Niue',
                        NF: 'Norfolk Island',
                        MP: 'Northern Mariana Islands',
                        NO: 'Norway',
                        OM: 'Oman',
                        PK: 'Pakistan',
                        PW: 'Palau',
                        PS: 'Palestinian Territory',
                        PA: 'Panama',
                        PG: 'Papua New Guinea',
                        PY: 'Paraguay',
                        PE: 'Peru',
                        PH: 'Philippines',
                        PN: 'Pitcairn',
                        PL: 'Poland',
                        PT: 'Portugal',
                        PR: 'Puerto Rico',
                        QA: 'Qatar',
                        RO: 'Romania',
                        RU: 'Russia',
                        RW: 'Rwanda',
                        KN: 'Saint Kitts and Nevis',
                        LC: 'Saint Lucia',
                        WS: 'Samoa',
                        SM: 'San Marino',
                        ST: 'Sao Tome and Principe',
                        SA: 'Saudi Arabia',
                        SN: 'Senegal',
                        RS: 'Serbia',
                        SC: 'Seychelles',
                        SL: 'Sierra Leone',
                        SG: 'Singapore',
                        SX: 'Sint Maarten',
                        SK: 'Slovakia',
                        SI: 'Slovenia',
                        SB: 'Solomon Islands',
                        SO: 'Somalia',
                        ZA: 'South Africa',
                        SS: 'South Sudan',
                        ES: 'Spain',
                        LK: 'Sri Lanka',
                        SD: 'Sudan',
                        SR: 'Suriname',
                        SZ: 'Swaziland',
                        SE: 'Sweden',
                        CH: 'Switzerland',
                        SY: 'Syria',
                        TW: 'Taiwan',
                        TJ: 'Tajikistan',
                        TZ: 'Tanzania',
                        TH: 'Thailand',
                        TG: 'Togo',
                        TK: 'Tokelau',
                        TO: 'Tonga',
                        TT: 'Trinidad and Tobago',
                        TN: 'Tunisia',
                        TR: 'Turkey',
                        TM: 'Turkmenistan',
                        TC: 'Turks and Caicos Islands',
                        TV: 'Tuvalu',
                        UG: 'Uganda',
                        UA: 'Ukraine',
                        AE: 'United Arab Emirates',
                        GB: 'United Kingdom',
                        US: 'United States',
                        UY: 'Uruguay',
                        UZ: 'Uzbekistan',
                        VU: 'Vanuatu',
                        VE: 'Venezuela, Bolivarian Republic of',
                        VN: 'Viet Nam',
                        VI: 'Virgin Islands',
                        YE: 'Yemen',
                        ZM: 'Zambia',
                        ZW: 'Zimbabwe'
                      })}
                      selectButtonClassName="py-0"
                      selectedSize={17.3}
                      optionsSize={17.3}
                      // howSelectedLabel={showSelectedLabel}
                      // showOptionLabel={showOptionLabel}
                      // placeholder={placeholder}
                      // searchable={searchable}
                      // searchPlaceholder={searchPlaceholder}
                      // alignOptionsToRight={alignOptionsToRight}
                      // fullWidth={fullWidth}
                      // disabled={disabled}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Col>

                  <Col
                    md={6}
                    className="mt-1"
                    controlId={`addresses-${address.id}`}
                  >
                    <RHFCheck
                      name={`addresses.${index}.isPrimary`}
                      label="Primary"
                      onChange={e => {
                        setValue(
                          `addresses.${index}.isPrimary`,
                          e.target.checked
                        );
                      }}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="12" controlId={`postcode-${address.postCode}`}>
                <RHFControl
                  name={`addresses.${index}.postcode`}
                  label="Post Code"
                  placeholder="add Zip/post Code"
                  required
                />
              </Col>
              <Col md="12" controlId={`line-${address.addressLine1}`}>
                <RHFControl
                  name={`addresses.${index}.addressLine1`}
                  label="Address Line 1"
                  placeholder="add Address Line 1"
                  required
                />
              </Col>
              <Col md="12" controlId={`line2-${address.addressLine2}`}>
                <RHFControl
                  name={`addresses.${index}.addressLine2`}
                  label="Address Line 2"
                  placeholder="add Address Line 2"
                />
              </Col>
              <Col md="12" controlId={`exampleZip-${address.cityOrTown}`}>
                <RHFControl
                  name={`addresses.${index}.city`}
                  label="City / Town"
                  placeholder="add City / Town"
                  required
                />
              </Col>
            </Row>
          </Card.Body>
          {index !== addresses.length - 1 && <hr className="m-0" />}
        </Card>
      ))}
      <div className="p-3 pt-0">
        <Button
          variant="light"
          className="bg-transparent border-0 fs--1 fs-md-0 shadow-none text-primary p-1"
          onClick={addNewAddress}
        >
          Add New Address
        </Button>
      </div>
    </Card>
  );
};

AddressInputs.propTypes = {
  addNewAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AddressInputs;
