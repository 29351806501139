import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import useCurrenciesOptions from 'hooks/useCurrenciesOptions';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_PACKAGE_CATEGORY = gql`
  mutation CreateProjectCurrency($currency_id: uuid!, $organisation_id: uuid!) {
    insert_donation_currency_one(
      object: { currency_id: $currency_id, organisation_id: $organisation_id }
    ) {
      currency_id
      id
      created_at
      organisation_id
      updated_at
    }
  }
`;

const UPDATE_PACKAGE_CATEGORY = gql`
  mutation UpdateProjectCurrency($id: uuid!, $currency_id: uuid!) {
    update_donation_currency_by_pk(
      pk_columns: { id: $id }
      _set: { currency_id: $currency_id }
    ) {
      currency_id
      created_at
      id
      organisation_id
      updated_at
    }
  }
`;

const DELETE_PACKAGE_CATEGORY = gql`
  mutation DeleteProjectCurrency($id: uuid!) {
    delete_donation_currency_by_pk(id: $id) {
      currency_id
      created_at
      id
      organisation_id
      updated_at
    }
  }
`;

const GET_PROJECT_CURRENCY = gql`
  query GetProjectCurrencies($where: donation_currency_bool_exp = {}) {
    donation_currency(where: $where) {
      currency_id
      created_at
      id
      organisation_id
      updated_at
      donation_currency_organisation {
        currency {
          abbreviation
          currency_name
          symbol
        }
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */
function ProjectCurrencies({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);

  // const [isOpen, setIsOpen] = useState(false);
  const { currenciesOptions, loading: currenciesLoading } =
    useCurrenciesOptions();
  const [createCategory, { loading: createLoading }] = useMutation(
    CREATE_PACKAGE_CATEGORY,
    {
      refetchQueries: ['GetProjectCurrencies'],
      onCompleted: () => {
        toast.success('Project currency created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateCategory, { loading: updateLoading }] = useMutation(
    UPDATE_PACKAGE_CATEGORY,
    {
      refetchQueries: ['GetProjectCurrencies'],
      onCompleted: () => {
        toast.success('Project currency updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteCategory, { loading: deleteLoading }] = useMutation(
    DELETE_PACKAGE_CATEGORY,
    {
      refetchQueries: ['GetProjectCurrencies'],
      onCompleted: () => {
        toast.success('Project currency deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getProjectCurrencies, { data }] = useLazyQuery(GET_PROJECT_CURRENCY);
  useEffect(() => {
    if (Object.keys(where).length)
      getProjectCurrencies({ variables: { ...where } });
  }, [where]);

  const handleCreate = ({ currency_id }) => {
    createCategory({
      variables: {
        currency_id,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateCategory({
      variables: {
        id,
        currency_id: data.currency_id
      }
    });
  };
  const handleDelete = id => {
    deleteCategory({
      variables: {
        id
      }
    });
  };
  const list = data?.donation_currency.map(projectCurrency => ({
    id: projectCurrency.id,
    values: [
      {
        value:
          projectCurrency.donation_currency_organisation.currency.symbol +
          projectCurrency.donation_currency_organisation.currency.abbreviation,
        name: 'currency_id'
      }
    ]
  }));
  // const handleOpenChange = isOpen => {
  //   setIsOpen(isOpen);
  // };
  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Project currency'}
      listTitle={'Current Currencies'}
      title={'Project Currencies'}
      inputs={[
        {
          name: 'currency_id',
          placeholder: 'Add Project currency',
          type: 'select',
          required: true,
          value: '',
          validation: yup.string().required('Project currency is required'),
          options: currenciesOptions,
          loading: currenciesLoading
        }
      ]}
      list={list}
      subject={subject}
      // hasOpenCheck
      // handleOpenChange={handleOpenChange}
      // isOpen={isOpen}
    />
  );
}

ProjectCurrencies.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default ProjectCurrencies;
