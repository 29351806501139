import { useQuery } from '@apollo/client';
import classNames from 'classnames';
import { GET_DONOR_BY_ID } from 'components/beneficiaries/donor/EditDonor';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { tooltipFormatter } from 'helpers/echart-utils';
import { getColor } from 'helpers/utils';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ProfileDonorInfo from './donor-profile-components/ProfileDonorInfo';
import ProfileInsights from './donor-profile-components/ProfileInsights';
// import RelatedContacts from './donor-profile-components/RelatedContacts';

const DonorProfile = () => {
  const yarns = [2020, 2021, 2022];
  const { id } = useParams();
  const { data: donorData } = useQuery(GET_DONOR_BY_ID, {
    variables: { id }
  });
  const data = [1330, 900, 1800];

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: yarns,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-800'),
        formatter: value => value.substring(0, 4),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        show: true,
        color: getColor('gray-800'),
        margin: 15
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
          type: 'dashed'
        }
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0,
      minInterval: 500
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: getColor('primary'),
          borderRadius: [3, 3, 0, 0]
        },
        barWidth: 13,
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '20%', bottom: '12%', top: '5%' }
  });

  const profileDetails = [
    { id: 1, title: 'LifeTime Contribution', value: '$18,461' },
    { id: 2, title: 'YTD Contribution', value: '$8,850' },
    { id: 3, title: 'Outstanding Pledges', value: '$0,0' },
    { id: 4, title: 'First Donation', value: '$850.0' },
    { id: 5, title: 'Most recent Donation', value: '$1500.0' },
    {
      id: 6,
      title: 'Created',
      value: '06/03/2018',
      valueClassName: 'text-400'
    },
    { id: 7, title: 'Created By', value: 'Ahmed Adel' },
    {
      id: 8,
      title: 'Current Assignee',
      value: 'Ali Mohamed',
      valueClassName: 'text-primary',
      link: '/'
    }
  ];

  return (
    <>
      {/* <Card className="mb-3"> */}
      {/* <FalconCardHeader
          className="my-1"
          title={
            <IconButton
              onClick={() => navigate(paths.donors)}
              icon={'arrow-left'}
              iconClassName="me-2"
              variant="link"
              className="px-0 fw-bold fs--1 fs-md-0"
            >
              Back To Donor List
            </IconButton>
          }
          light={false}
          titleTag="div"
          menuClassName="align-items-center"
          endEl={
            <Can
              I={userActions.create}
              a={permissionsKeyMap.donation.children.donations}
            >
              <IconButton
                icon="plus"
                transform="shrink-3"
                onClick={() =>
                  navigate(
                    parseRouteParams(
                      paths.configNewDonation,
                      {},
                      {
                        donorId: id
                      }
                    )
                  )
                }
              >
                <span className="d-none d-md-inline-block ms-2">
                  Add Donation
                </span>
              </IconButton>
            </Can>
          }
        /> */}
      {/* </Card> */}
      <Card className="mb-3 opacity-25 d-none">
        <FalconCardHeader
          title={<h4 className="fw-bold">Profile Overview</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
          // endEl={<CardDropdown btnRevealClass="btn-falcon-default" />}
        />
        <Card.Body>
          <Row>
            <Col md={6}>
              <Table size="sm" borderless className="font-sans-serif mb-0">
                <tbody>
                  {profileDetails?.map(detail => (
                    <tr key={detail.id}>
                      <td className="text-start ps-0">{detail.title}</td>
                      <td
                        className={classNames(
                          'text-start',
                          detail.valueClassName
                        )}
                      >
                        {detail.link ? (
                          <a href={detail.link}>{detail.value}</a>
                        ) : (
                          detail.value
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <Col md={1} />
            <Col md={5}>
              <div>
                <ReactEChartsCore
                  echarts={echarts}
                  option={getOption()}
                  style={{ height: '14.75rem' }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <ProfileDonorInfo donorData={donorData} />
      <ProfileInsights />
      {/* <RelatedContacts /> */}
    </>
  );
};

export default DonorProfile;
