import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import {
  FormProvider,
  RHFControl,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';
import { ownerRegistrationResolver } from './ownerRegistrationScheme';
import LoadingButton from 'components/common/LoadingButton';
import { useEffect } from 'react';

export const titleOptions = [
  { value: 'Mr', label: 'Mr' },
  { value: 'Mrs', label: 'Mrs' },
  { value: 'Ms', label: 'Ms' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Md', label: 'Md' }
];

export default function OwnerRegistrationForm({
  onSubmit,
  loading,
  isEdit,
  initialValues
}) {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initialValues ?? {
      title: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      confirmPhoneNumber: '',
      email: '',
      companyEmail: '',
      password: '',
      confirmPassword: ''
    },
    resolver: ownerRegistrationResolver
  });

  const { handleSubmit, setValue } = methods;
  useEffect(() => {
    setValue('isEdit', isEdit);
  }, [isEdit, setValue]);
  const submitButtonLabel = isEdit ? 'Save' : 'Create Account';
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column gap-3">
        <Row>
          <Col md={6}>
            <RHFSelect
              label={'Title'}
              name={'title'}
              options={titleOptions}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RHFControl
              label={'First Name'}
              name={'firstName'}
              placeholder={'First Name'}
              required
            />
          </Col>
          <Col md={6}>
            <RHFControl
              label={'Last Name'}
              name={'lastName'}
              placeholder={'Last Name'}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RHFPhoneInput
              label={'Phone Number'}
              name={'phoneNumber'}
              placeholder={'Phone Number'}
              required
            />
          </Col>
          <Col md={6}>
            <RHFPhoneInput
              label={'Confirm Phone Number'}
              name={'confirmPhoneNumber'}
              placeholder={'Confirm Phone Number'}
              required
            />
          </Col>
        </Row>
        <Row>
          <RHFControl
            label={'Personal Email Address'}
            name={'email'}
            placeholder={'Personal Email Address'}
            type={'email'}
            disabled={isEdit}
            required
          />
        </Row>
        <Row>
          <RHFControl
            label={'Company Email Address'}
            name={'companyEmail'}
            placeholder={'Company Email Address'}
            type={'email'}
          />
        </Row>
        {!isEdit && (
          <Row>
            <Col md={6}>
              <RHFControl
                label={'Password'}
                name={'password'}
                placeholder={'Password'}
                type="password"
                required
              />
            </Col>
            <Col md={6}>
              <RHFControl
                label={'Confirm Password'}
                name={'confirmPassword'}
                placeholder={'Confirm Password'}
                type="password"
                required
              />
            </Col>
          </Row>
        )}
        <LoadingButton loading={loading} type="submit" className="mb-2">
          {submitButtonLabel}
        </LoadingButton>
      </div>
    </FormProvider>
  );
}

// props validation
OwnerRegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object
};
