import { gql, useQuery } from '@apollo/client';
import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import LoadingContainer from 'components/common/LoadingContainer';
import { useParams } from 'react-router-dom';

export const DonorContext = createContext({
  donor: null
});

/* -------------------------------------------------------------------------- */
const GET_DONOR = gql`
  query GetDonor($id: uuid!) {
    donars_by_pk(id: $id) {
      first_name
      id
      last_name
      title
      user {
        first_name
        last_name
        id
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

export function DonorContextProvider({ children }) {
  const { id } = useParams();
  const { data: donorData, loading: donorLoading } = useQuery(GET_DONOR, {
    variables: { id }
  });
  return (
    <DonorContext.Provider
      value={{
        donor: donorData?.donars_by_pk ?? {}
      }}
    >
      {donorLoading ? <LoadingContainer /> : children}
    </DonorContext.Provider>
  );
}

DonorContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDonorContext = () => {
  const context = useContext(DonorContext);
  if (context === undefined) {
    throw new Error(
      'useDonorContext must be used within a DonorContextProvider'
    );
  }
  return context;
};

export default DonorContext;
