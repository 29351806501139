import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import {
  FormProvider,
  RHFControl,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';
import {
  getCitiesOptions,
  getCountriesOptions
} from 'data/options/countris-and-cities';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

/* -------------------------------------------------------------------------- */

export const schoolSchema = Yup.object().shape({
  schoolName: Yup.string().required('Required Field'),
  schoolHeadTeacher: Yup.string().required('Required Field'),
  serialNumber: Yup.number().required('Required Field'),
  email: Yup.string().email().required('Required Field'),
  contact: Yup.string().required('Required Field'),
  address: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  city: Yup.string().required('Required Field')
});
/* -------------------------------------------------------------------------- */
export default function SchoolForm({ onSubmit, initValues, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      serialNumber: '',
      schoolName: '',
      email: '',
      contact: '',
      address: '',
      country: '',
      city: '',
      schoolHeadTeacher: ''
    },
    resolver: yupResolver(schoolSchema)
  });

  const { handleSubmit, watch, reset } = methods;

  const country = watch('country');
  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);
  useEffect(() => {
    if (initValues) {
      reset({
        serialNumber: initValues.serial_number,
        contact: initValues.school_number,
        address: initValues.school_address,
        city: initValues.school_city,
        email: initValues.school_email,
        schoolName: initValues.school_name,
        country: initValues.country,
        schoolHeadTeacher: initValues.school_headteacher
      });
    }
  }, [initValues]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header>
            <Card.Title className="fw-medium">Primary Information</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Row className="mb-3 g-3">
              <Col md={6}>
                <RHFControl
                  name="serialNumber"
                  placeholder="Serial Number"
                  label="Serial Number"
                  required
                  type="number"
                />
              </Col>

              <Col md="6">
                <RHFControl
                  name="schoolName"
                  placeholder="School Name"
                  label="School Name"
                  required
                />
              </Col>
              <Col md={12}>
                <RHFControl
                  name="schoolHeadTeacher"
                  placeholder="School Head Teacher"
                  label="School Head Teacher"
                  required
                />
              </Col>
              <Col md="6">
                <RHFControl
                  name="email"
                  placeholder="Email"
                  label="Email"
                  required
                />
              </Col>
              <Col md="6">
                <RHFPhoneInput
                  name="contact"
                  placeholder="Contact Number"
                  label="Contact Number"
                  required
                />
              </Col>
              <Col md="12">
                <RHFControl
                  name="address"
                  placeholder="Address"
                  label="Address"
                  required
                />
              </Col>

              <Col md="6">
                <RHFSelect
                  label={'Country'}
                  name={'country'}
                  options={countriesOptions}
                  required
                />
              </Col>
              <Col md="6">
                <RHFSelect
                  name="city"
                  label="city"
                  options={citiesOptions}
                  isDisabled={citiesOptions.length === 0}
                  required
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* </FormProvider> */}
        <Card>
          <Card.Body>
            <Flex justifyContent={'between'} className="align-items-center">
              <p className="mb-0 fs-2 fw-medium">
                {initValues ? 'Update School' : 'Create School'}
              </p>
              <LoadingButton
                loading={loading}
                type="submit"
                className="px-5 py-2"
              >
                Submit
              </LoadingButton>
            </Flex>
          </Card.Body>
        </Card>
      </FormProvider>
    </>
  );
}

SchoolForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.func.object,
  loading: PropTypes.bool
};
