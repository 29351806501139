import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import { weDonorInvoiceData } from 'data/weraise-data/donor-data';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import DonorCommunicationModal from './modals/DonorCommunicationModal';

const DonorCommunications = () => {
  const [modalShow, setModalShow] = useState(false);

  const columns = [
    {
      accessor: 'ref',
      Header: 'Ref',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { ref } = rowData.row.original;
        return (
          <Button
            onClick={() => setModalShow(true)}
            variant="link"
            size="sm"
            className="p-0"
          >
            {ref}
          </Button>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return (
          <SoftBadge pill bg={rowData.row.original.variant} className="me-2">
            {rowData.row.original.status}{' '}
            <FontAwesomeIcon icon={rowData.row.original.icon} />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'title',
      Header: 'Title',
      cellProps: {
        className: 'py-2'
      }
    }
    // {
    //   accessor: 'id',
    //   Header: '',
    //   cellProps: {
    //     className: 'py-2 text-end'
    //   },
    //   disableSortBy: true,

    //   Cell: rowData => {
    //     const { id } = rowData.row;
    //     return (
    //       <TableRowAction
    //         onCLickEdit={() => console.log(id)}
    //         onCLickDelete={() => console.log(id)}
    //       />
    //     );
    //   }
    // }
  ];

  return (
    <>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {weDonorInvoiceData && (
            <CustomAdvanceTable
              data={weDonorInvoiceData}
              columns={columns}
              title="Communications"
              showSearchInput
              searchInputPlaceHolder="Search"
              addNew
              perPage={10}
              subject={permissionsKeyMap.donor.children.donors}
            />
          )}
        </Col>
      </Row>
      <DonorCommunicationModal
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
};

export default DonorCommunications;
