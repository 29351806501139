import AppContext from 'context/Context';
import is from 'is_js';
import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import AllBeneficiaries from 'components/beneficiaries';
import SponsoredList from 'components/beneficiaries/SponsoredList';
import UnSponsoredList from 'components/beneficiaries/UnSponsoredList';
import SponsorshipValues from 'components/beneficiaries/sponsorship-settings';
import { CloseButton } from 'components/common/Toast';
import Dashboard from 'components/dashboards';
import DonationPackages from 'components/donations/donation-packages';
import DonationSettings from 'components/donations/donation-settings';
import UrgentAppeals from 'components/donations/urgent-appeals';
import Donors from 'components/donor';
import CreateDonor from 'components/donor/CreateDonor';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import ProjectsKanban from 'components/projects';
import CreateProject from 'components/projects/CreateProject';
import ProjectLayout from 'components/projects/ProjectLayout';
import ProjectsList from 'components/projects/ProjectsList';
import DonorLayout from '../components/donor-details/DonorLayout';
import ErrorLayout from './ErrorLayout';
import MainLayout from './MainLayout';
// import ClaimedGiftAid from 'components/gift-aid/claimed-gift-aid';
// import UnclaimedTransactions from 'components/gift-aid/unclaimed-transactions';
// import SingleClaimListing from 'components/gift-aid/single-claim-listing';
import CreateDonation from 'components/add-donation';
import ForgetPassword from 'components/authenitcation/login/ForgetPassword';
import Login from 'components/authenitcation/login/Login';
import PasswordReset from 'components/authenitcation/login/PasswordReset';
import OrganizationRegistration from 'components/authenitcation/registration/OrganizationRegistration';
import RegistrationLayout from 'components/authenitcation/registration/RegistrationLayout';
import RegistrationRoutes from 'components/authenitcation/registration/RegistrationRoutes';
import CreateBeneficiary from 'components/beneficiaries/CreateBeneficiary';
import EditBeneficiary from 'components/beneficiaries/EditBeneficiary';
import LoadingScreen from 'components/common/LoadingScreen';
import CreatePackage from 'components/donations/donation-packages/CreatePackage';
import EditPackage from 'components/donations/donation-packages/EditPackage';
import CreateUrgentAppeal from 'components/donations/urgent-appeals/CreateUrgentAppeal';
import EditUrgentAppeal from 'components/donations/urgent-appeals/EditUrgentAppeal';
import EditDonor from 'components/donor/EditDonor';
import GiftAid from 'components/gift-aid';
import BranchesSelection from 'components/organization/BranchesSelection';
import OrganizationCreateBranch from 'components/organization/CreateBranch';
import OrganizationEditBranch from 'components/organization/EditBranch';
import OrganizationSettings from 'components/settings/OrganizationSettings';
import PersonalSettings from 'components/settings/PersonalSettings';
import Branches from 'components/settings/branches/Branches';
import CreateBranch from 'components/settings/branches/CreateBranch';
import EditBranch from 'components/settings/branches/EditBranch';
import SMTPSettings from 'components/settings/smpt/SMTPSettings';
import CreateUser from 'components/settings/users/CreateUser';
import UsersList from 'components/settings/users/UsersList';
import AuthContext from 'context/authContext';
import paths from 'routes/paths';
import AuthSimpleLayout from './AuthSimpleLayout';
// import EditOrganization from 'components/settings/organization/OrganizationRegistration';
import DonationLayout from 'components/Home/DonationLayout';
import SessionOut from 'components/authenitcation/session-out/SessionOut';
import VerifyEmail from 'components/authenitcation/verify-email/VerifyEmail';
import BeneficiaryLayout from 'components/beneficiaries/BeneficiaryLayout';
import AddHospital from 'components/beneficiaries/hospital/AddHospital';
import EditHospital from 'components/beneficiaries/hospital/EditHospital';
import HospitalList from 'components/beneficiaries/hospital/HospitalList';
import AddMosque from 'components/beneficiaries/mosque/AddMosque';
import EditMosque from 'components/beneficiaries/mosque/EditMosque';
import MosqueList from 'components/beneficiaries/mosque/MosqueList';
import AddOrphanage from 'components/beneficiaries/orphanage/AddOrphanage';
import EditOrphanage from 'components/beneficiaries/orphanage/EditOrphanage';
import OrphanageList from 'components/beneficiaries/orphanage/OrphanageList';
import AddSchool from 'components/beneficiaries/school/AddSchool';
import EditSchool from 'components/beneficiaries/school/EditSchool';
import SchoolList from 'components/beneficiaries/school/SchoolList';
import CreateSponsorship from 'components/beneficiaries/sponsorships/CreateSponsorship';
import EditProject from 'components/projects/EditProject';
import ProjectMilestones from 'components/projects/ProjectMilestones';
import EventScheduler from 'components/scheduler';
import PaymentSettings from 'components/settings/payment/PaymentSettings';
import EditUser from 'components/settings/users/EditUser';
import useIdle from 'hooks/useIdle';
import PermissionWrapper from 'routes/PermissionWrapper';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import { VerifyEmailAlert } from 'components/authenitcation/registration/VerifyEmailAlert';

const Layout = () => {
  const {
    isAuthenticated,
    isInitialized,
    organization,
    isOrganizationAdmin,
    logout
  } = useContext(AuthContext);
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarPosition]);
  const onTimeOut = () => {
    logout({ sessionOut: true });
  };
  useIdle(onTimeOut, isAuthenticated);
  return isInitialized ? (
    <>
      <Routes>
        <Route element={<ErrorLayout />}>
          <Route path={paths.notFound} element={<Error404 />} />
          <Route path={paths.serverError} element={<Error500 />} />
        </Route>
        <Route path={paths.donationForm} element={<DonationLayout />} />
        <Route element={<AuthSimpleLayout />}>
          <Route path={paths.verifyEmail} element={<VerifyEmail />} />
        </Route>

        {!isAuthenticated ? (
          <>
            <Route
              path={paths.register + '/*'}
              element={<RegistrationRoutes />}
            />
            <Route element={<AuthSimpleLayout />}>
              {/* <Route path={paths.verifyEmail} element={<VerifyEmail />} /> */}
              <Route path={paths.login} element={<Login />} />
              <Route path={paths.sessionOut} element={<SessionOut />} />

              <Route path={paths.forgotPassword} element={<ForgetPassword />} />
              <Route path={paths.resetPassword} element={<PasswordReset />} />
            </Route>
          </>
        ) : (
          <>
            {!organization ? (
              <Route element={<RegistrationLayout />}>
                <Route
                  path={paths.registerBusinessInfo}
                  element={<OrganizationRegistration />}
                />
                <Route
                  path="/"
                  element={<Navigate to={paths.registerBusinessInfo} replace />}
                />
              </Route>
            ) : (
              <>
                <Route element={<AuthSimpleLayout />}>
                  <Route
                    path={paths.verifyEmailAlert}
                    element={<VerifyEmailAlert />}
                  />
                </Route>
                <Route element={<MainLayout />}>
                  <Route
                    path={paths.giftAid + '/*'}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.giftAid.children.giftAid}
                        action={userActions.read}
                      >
                        <GiftAid />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.dashboard}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.dashboard.children.dashboard}
                        action={userActions.read}
                      >
                        <Dashboard />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addNewDonation + '/*'}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.donation.children.donations}
                        action={userActions.create}
                      >
                        <CreateDonation />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.donors}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.donor.children.donors}
                        action={userActions.read}
                      >
                        <Donors />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.donorDetails + '/*'}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.donor.children.donors}
                        action={userActions.read}
                      >
                        <DonorLayout />
                      </PermissionWrapper>
                    }
                  />
                  {/* <Route
                  path={paths.createNewSponsor}
                  element={<CreateNewSponsor />}
                /> */}
                  <Route
                    path={paths.createNewSponsorship}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.sponsorships
                        }
                        action={userActions.create}
                      >
                        <CreateSponsorship />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addNewDonar}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.donor.children.donors}
                        action={userActions.create}
                      >
                        <CreateDonor />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editDonor}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.donor.children.donors}
                        action={userActions.update}
                      >
                        <EditDonor />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.beneficiaries}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.beneficiaries
                        }
                        action={userActions.read}
                      >
                        <AllBeneficiaries />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.beneficiaryDetails + '/*'}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.beneficiaries
                        }
                        action={userActions.read}
                      >
                        <BeneficiaryLayout />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.sponsoredList + '/*'}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.sponsoredList
                        }
                        action={userActions.read}
                      >
                        <SponsoredList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.unsponsoredList}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.unsponsoredList
                        }
                        action={userActions.read}
                      >
                        <UnSponsoredList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.projectMilestones}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.project.children.projects}
                        action={userActions.read}
                      >
                        <ProjectMilestones />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.projectsKanban}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.project.children.projects}
                        action={userActions.read}
                      >
                        <ProjectsKanban />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.projectsList}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.project.children.projects}
                        action={userActions.read}
                      >
                        <ProjectsList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.createProject}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.project.children.projects}
                        action={userActions.create}
                      >
                        <CreateProject />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editProject}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.project.children.projects}
                        action={userActions.update}
                      >
                        <EditProject />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.sponsorshipValues}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children
                            .sponsorshipValues
                        }
                        action={userActions.read}
                      >
                        <SponsorshipValues />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addBeneficiary}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.beneficiaries
                        }
                        action={userActions.create}
                      >
                        <CreateBeneficiary />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editBeneficiary}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.beneficiaries
                        }
                        action={userActions.update}
                      >
                        <EditBeneficiary />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.donationSettings}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.donationSettings
                        }
                        action={userActions.read}
                      >
                        <DonationSettings />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.donationPackages}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.donationPackages
                        }
                        action={userActions.read}
                      >
                        <DonationPackages />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.createPackage}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.donationPackages
                        }
                        action={userActions.create}
                      >
                        <CreatePackage />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editPackage}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.donationPackages
                        }
                        action={userActions.update}
                      >
                        <EditPackage />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.urgentAppeals}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.urgentAppeals
                        }
                        action={userActions.read}
                      >
                        <UrgentAppeals />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.createUrgentAppeal}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.urgentAppeals
                        }
                        action={userActions.create}
                      >
                        <CreateUrgentAppeal />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editUrgentAppeal}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.donation.children.urgentAppeals
                        }
                        action={userActions.update}
                      >
                        <EditUrgentAppeal />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.projectDetails + '/*'}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.project.children.projects}
                        action={userActions.read}
                      >
                        <ProjectLayout />
                      </PermissionWrapper>
                    }
                  />
                  {/* settings */}
                  <Route
                    path={paths.personalSettings}
                    element={
                      <PermissionWrapper>
                        <PersonalSettings />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.school}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.sponsorship.children.school}
                        action={userActions.read}
                      >
                        <SchoolList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addSchool}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.sponsorship.children.school}
                        action={userActions.create}
                      >
                        <AddSchool />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editSchool}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.sponsorship.children.school}
                        action={userActions.update}
                      >
                        <EditSchool />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.mosque}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.sponsorship.children.mosque}
                        action={userActions.read}
                      >
                        <MosqueList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addMosque}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.sponsorship.children.mosque}
                        action={userActions.create}
                      >
                        <AddMosque />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editMosque}
                    element={
                      <PermissionWrapper
                        subject={permissionsKeyMap.sponsorship.children.mosque}
                        action={userActions.update}
                      >
                        <EditMosque />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.hospital}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.hospital
                        }
                        action={userActions.read}
                      >
                        <HospitalList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addHospital}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.hospital
                        }
                        action={userActions.create}
                      >
                        <AddHospital />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editHospital}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.hospital
                        }
                        action={userActions.update}
                      >
                        <EditHospital />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.orphanage}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.orphanage
                        }
                        action={userActions.read}
                      >
                        <OrphanageList />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.addOrphanage}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.orphanage
                        }
                        action={userActions.create}
                      >
                        <AddOrphanage />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path={paths.editOrphanage}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.sponsorship.children.orphanage
                        }
                        action={userActions.create}
                      >
                        <EditOrphanage />
                      </PermissionWrapper>
                    }
                  />{' '}
                  <Route
                    path={paths.eventSchedule}
                    element={
                      <PermissionWrapper
                        subject={
                          permissionsKeyMap.eventScheduler.children
                            .eventScheduler
                        }
                        action={
                          permissionsKeyMap.eventScheduler.children
                            .eventScheduler
                        }
                      >
                        <EventScheduler />
                      </PermissionWrapper>
                    }
                  />
                  <Route
                    path="/"
                    element={<Navigate to={paths.dashboard} replace />}
                  />
                </Route>

                <Route element={<MainLayout type={'organization'} />}>
                  <Route
                    path={paths.branchSelection}
                    element={<BranchesSelection />}
                  />
                  {isOrganizationAdmin && (
                    <>
                      <Route
                        path={paths.organizationSettings}
                        element={
                          // <PermissionWrapper
                          //   subject={
                          //     permissionsKeyMap.settings.children
                          //       .organizationDetails
                          //   }
                          //   action={userActions.read}
                          // >
                          <OrganizationSettings />
                          // </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.branchCreate}
                        element={<OrganizationCreateBranch />}
                      />
                      <Route
                        path={paths.branchEdit}
                        element={<OrganizationEditBranch />}
                      />
                      <Route
                        path={paths.branchesSetting}
                        element={
                          // <PermissionWrapper
                          //   subject={
                          //     permissionsKeyMap.settings.children.branches
                          //   }
                          //   action={userActions.read}
                          // >
                          <Branches />
                          // </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.userRolesSetting}
                        element={
                          <PermissionWrapper
                            subject={permissionsKeyMap.settings.children.users}
                            action={userActions.read}
                          >
                            <UsersList />
                          </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.paymentSettings}
                        element={
                          <PermissionWrapper
                            subject={
                              permissionsKeyMap.settings.children.payment
                            }
                            action={userActions.read}
                          >
                            <PaymentSettings />
                          </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.smtpSettings}
                        element={
                          <PermissionWrapper
                            subject={permissionsKeyMap.settings.children.smtp}
                            action={userActions.read}
                          >
                            <SMTPSettings />
                          </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.createBranch}
                        element={
                          <PermissionWrapper
                            subject={
                              permissionsKeyMap.settings.children.branches
                            }
                            action={userActions.create}
                          >
                            <CreateBranch />
                          </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.editBranch}
                        element={
                          <PermissionWrapper
                            subject={
                              permissionsKeyMap.settings.children.branches
                            }
                            action={userActions.update}
                          >
                            <EditBranch />
                          </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.editUser}
                        element={
                          <PermissionWrapper
                            subject={permissionsKeyMap.settings.children.users}
                            action={userActions.update}
                          >
                            <EditUser />
                          </PermissionWrapper>
                        }
                      />
                      <Route
                        path={paths.createNewUser}
                        element={
                          <PermissionWrapper
                            subject={permissionsKeyMap.settings.children.users}
                            action={userActions.create}
                          >
                            <CreateUser />
                          </PermissionWrapper>
                        }
                      />
                    </>
                  )}
                </Route>
              </>
            )}
          </>
        )}
        {!isAuthenticated ? (
          <Route path="*" element={<Navigate to={paths.login} replace />} />
        ) : (
          <Route path="*" element={<Navigate to={paths.notFound} replace />} />
        )}
      </Routes>
      {/* <SettingsToggle />
      <SettingsPanel /> */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  ) : (
    <LoadingScreen />
  );
};

export default Layout;
