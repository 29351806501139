import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import Flex from 'components/common/Flex';
import { weGiftEidData } from 'data/weraise-data/donor-data';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

const DonorGiftAid = () => {
  const columns = [
    {
      accessor: 'ref',
      Header: 'Ref.',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => <Link to="#">{rowData.row.original.ref}</Link>
    },
    {
      accessor: 'date',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'startOn',
      Header: 'Started on',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'endOn',
      Header: 'End on',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'eligibility',
      Header: 'Eligibility',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (rowData.row.original.description ? 'yes' : 'No')
    },
    {
      accessor: 'declared_via',
      Header: 'Declared Via',
      cellProps: {
        className: 'py-2'
      }
    }
    // {
    //   accessor: 'id',
    //   Header: '',
    //   cellProps: {
    //     className: 'py-2 text-end'
    //   },
    //   disableSortBy: true,

    //   Cell: rowData => {
    //     const { id } = rowData.row;
    //     return (
    //       <TableRowAction
    //         onCLickEdit={() => console.log(id)}
    //         onCLickDelete={() => console.log(id)}
    //       />
    //     );
    //   }
    // }
  ];

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="p-4 pb-0">
          <Card.Title className="fs-2 fw-bold">Insights</Card.Title>
        </Card.Header>
        <Card.Body className="p-4">
          <Row>
            <Col md={4} className="pe-4">
              <Flex className={'border-md-end mb-3 mb-md-0'}>
                <FontAwesomeIcon
                  icon={'circle'}
                  className="me-2 text-primary"
                />
                <div className="fw-medium">
                  <p className="mb-2 text-600">Eligible For Gift Aid?</p>
                  <p className="fs-2 mb-0 text-900">
                    Yes{' '}
                    <FontAwesomeIcon
                      icon={'check'}
                      className="text-success border p-1 fs-0 rounded-5 border-success"
                    />
                  </p>
                </div>
              </Flex>
            </Col>
            <Col md={4} className="pe-4">
              <Flex className={'border-md-end mb-3 mb-md-0'}>
                <FontAwesomeIcon
                  icon={'circle'}
                  className="me-2 text-warning"
                />
                <div className="fw-medium">
                  <p className="mb-2 text-600">Awaiting Submission</p>
                  <p className="fs-2 mb-0 text-900">$ 862.00</p>
                </div>
              </Flex>
            </Col>
            <Col md={4}>
              <Flex>
                <FontAwesomeIcon
                  icon={'circle'}
                  className="me-2 text-warning"
                />
                <div className="fw-medium">
                  <p className="mb-2 text-600">Claimed To Date</p>
                  <p className="fs-2 mb-0 text-900">$2,525.50</p>
                </div>
              </Flex>
            </Col>
          </Row>
          <hr />
          <div>
            <p className="fs-2 fw-bold">Current Declarations</p>
            <div className="fw-medium">
              <Flex justifyContent={'between'}>
                <p className="text-800">Eligible</p>
                <p className="text-800">
                  <FontAwesomeIcon
                    icon={'circle'}
                    className="me-2 text-success"
                  />
                  Yes
                </p>
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Valid From</p>
                <p className="text-800">05/10/2021</p>
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Declration Date</p>
                <p className="text-800">05/10/2022</p>
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Notes</p>
                <FontAwesomeIcon icon={'pen'} className="text-800" />
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800">Attachments</p>
                <FontAwesomeIcon icon={'pen'} className="text-800" />
              </Flex>
              <Flex justifyContent={'between'}>
                <p className="text-800 mb-0">Status</p>
                <p className="text-success mb-0">Active</p>
              </Flex>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {weGiftEidData && (
            <CustomAdvanceTable
              data={weGiftEidData}
              columns={columns}
              title="Declarations log"
              showSearchInput
              searchInputPlaceHolder="Search"
              addNew
              perPage={10}
              subject={permissionsKeyMap.donor.children.donors}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DonorGiftAid;
