import { useLazyQuery, useMutation } from '@apollo/client';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';
import useBranchPermission from 'hooks/useBranchPermission';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { ADD_SCHOOL_STUDENTS, CREATE_SCHOOL } from '../mutations';
import { GET_BENEFICIARY_BY_ID, GET_SCHOOLS } from '../queries';
import SchoolForm from './SchoolForm';
import SelectStudentModal from './SelectStudentModal';

function AddSchool() {
  const schoolColumns = [
    {
      accessor: 'first_name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'DOB',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { DOB } = rowData.row.original;
        return <>{dayjs(DOB).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender'
    },
    {
      accessor: 'created_at',
      Header: 'Registration',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'inspiration',
      Header: 'Aspiration'
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickDelete={() => removeStudent(id)}
            isNotEdit
            subject={permissionsKeyMap.sponsorship.children.school}
          />
        );
      }
    }
  ];

  const [showStudentAddModal, setShowStudentAddModal] = useState(false);
  const navigate = useNavigate();
  const { where } = useBranchPermission();

  const { organization, user, selectedBranchId } = useContext(AuthContext);

  const [listOfStudents, setListOfStudents] = useState([]);

  const [createSchool, { loading }] = useMutation(CREATE_SCHOOL, {
    onCompleted: data => {
      toast.success('School Created Successfully');
      const tmp = listOfStudents.map(item => {
        return {
          branch_id: selectedBranchId,
          organisation_id: organization.id,
          orphan_id: item.id,
          school_id: data?.insert_schools_one.id,
          user_id: user.id
        };
      });
      if (tmp.length !== 0) addSchoolStudents({ variables: { objects: tmp } });
      else navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingSchool = cache.readQuery({
        query: GET_SCHOOLS,
        variables: { where }
      });

      if (!existingSchool) return;
      cache.writeQuery({
        query: GET_SCHOOLS,
        variables: { where },
        data: {
          schools: [...existingSchool.schools, data?.insert_schools_one]
        }
      });
    }
  });

  const [addSchoolStudents] = useMutation(ADD_SCHOOL_STUDENTS, {
    onCompleted: () => {
      toast.success('Students Added Successfully');
      navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const submit = values => {
    createSchool({
      variables: {
        branch_id: selectedBranchId,
        organisation_id: organization.id,
        school_address: values.address,
        school_city: values.city,
        school_email: values.email,
        school_name: values.schoolName,
        serial_number: values.serialNumber,
        school_number: values.contact,
        school_headteacher: values.schoolHeadTeacher,
        country: values.country
      }
    });
  };

  const [getBeneficiaryById, { data: dataBeneficiary }] = useLazyQuery(
    GET_BENEFICIARY_BY_ID
  );

  useEffect(() => {
    if (dataBeneficiary?.orphans_by_pk) {
      setListOfStudents([...listOfStudents, dataBeneficiary?.orphans_by_pk]);
    }
  }, [dataBeneficiary]);

  const removeStudent = id => {
    setListOfStudents(listOfStudents.filter(item => item.id !== id));
  };

  const submitStudent = value => {
    getBeneficiaryById({
      variables: {
        id: value
      }
    });
  };

  return (
    <>
      <SchoolForm onSubmit={submit} loading={loading} />
      <SelectStudentModal
        show={showStudentAddModal}
        onHide={() => setShowStudentAddModal(false)}
        onSubmit={submitStudent}
      />
      <Row className="mt-3">
        <Col md={12} xxl={12} className="h-md-100">
          {listOfStudents && (
            <CustomAdvanceTable
              data={listOfStudents}
              columns={schoolColumns}
              title="Beneficiary"
              addButtonLabel="Add Beneficiary"
              addButtonIcon="user"
              onClickAddNew={() => {
                setShowStudentAddModal(true);
              }}
              addNew
              subject={permissionsKeyMap.sponsorship.children.school}
              bulkActionsOptions
              handleBulkAction
              selection={true}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default AddSchool;
