import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import profilePictureAvatar from 'assets/img/placeholders/profile-picture-avatar.png';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Image, ProgressBar } from 'react-bootstrap';
import Divider from '../Divider';
import Flex from '../Flex';
import SoftBadge from '../SoftBadge';

export default function Account({ score, type, user }) {
  const { first_name, last_name, title, image, id } = user || {};
  const fullName = `${first_name} ${last_name}`;
  return (
    <Card className="mb-3">
      <Card.Body>
        <Flex justifyContent="between" alignItems="center" className={'mb-3'}>
          <h6 className="mb-0">{type} Account</h6>
          <SoftBadge pill bg="primary" className="align-self-center fs--2">
            <FontAwesomeIcon icon="user" className="me-1" /> Individual
          </SoftBadge>
        </Flex>
        <Divider />
        <div className="d-flex">
          <div className="align-self-center">
            <Image
              src={image || profilePictureAvatar}
              rounded="circle"
              height={'80px'}
              className="border border-5 border-white rounded-circle shadow-sm mb-2"
            />
          </div>
          <div className="align-self-center">
            <p className="mb-1 fs--1 text-primary fw-medium">
              {title} {fullName}
            </p>
            <p className="mb-0 fs--2 text-700 font-base">ID AC {id}</p>
            <SoftBadge pill bg="primary" className="align-self-center fs--2">
              <FontAwesomeIcon icon="user" className="me-1" /> Individual
            </SoftBadge>
          </div>
        </div>
        {score && (
          <>
            <hr className="text-200" />

            <Flex justifyContent={'between'} alignItems="center">
              <p className="mb-0 fs--2 fw-medium me-5">
                Score: <span className="text-success fw-semi-bold">{0}</span>
              </p>
              <div className="flex-1">
                <ProgressBar
                  variant="success"
                  now={0}
                  style={{ height: '5px' }}
                />
              </div>
            </Flex>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

Account.propTypes = {
  user: PropTypes.object,
  score: PropTypes.bool,
  type: PropTypes.string
};
