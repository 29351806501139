import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { donationInfo, weDonorGivingData } from 'data/weraise-data/donor-data';
import TableRow from 'components/we-raise-common/session-by-browser/TableRow';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import * as echarts from 'echarts/core';

export const getOptions = () => ({
  color: [
    getColor('primary'),
    getColor('success'),
    getColor('info'),
    getColor('danger')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: params =>
      `<strong>${params.data.name}:</strong> ${params.data.value}%`
  },

  legend: { show: false },
  series: [
    {
      type: 'pie',
      radius: ['100%', '65%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderWidth: 2,
        borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      data: weDonorGivingData
    }
  ]
});

export const DonationData = ({ data, isLastItem }) => (
  <Flex className={!isLastItem ? 'border-md-end me-md-4 me-lg-5' : 'border-0'}>
    <FontAwesomeIcon
      icon={'circle'}
      className={classNames('me-2 mt-1', data.statusColor)}
      size="xs"
    />
    <div className="fw-medium">
      <p className="mb-0 text-600">{data.title}</p>
      {data.amount ? (
        <p className="mb-0 fs-2">
          {data.amount}{' '}
          {data.notification ? (
            <span
              className="border d-inline-flex fs--1 ms-1 justify-content-center rounded-circle"
              style={{ height: 20, width: 20 }}
            >
              {data.notification}
            </span>
          ) : null}
        </p>
      ) : null}

      {data.info && (
        <p className={classNames('mb-0 fs-2', data.statusColor)}>{data.info}</p>
      )}

      {!data.donationCount ? (
        <div>
          {data.lastDonation ? (
            <>
              <p className="mb-0 text-500">last Donation</p>
              <p className="mb-0">{data.lastDonation}</p>
            </>
          ) : (
            <p>No Period Comparison</p>
          )}
        </div>
      ) : (
        <p className="mb-0 text-500">
          <span className="text-900">{data.donationCount}</span> one-off
          donations per year
        </p>
      )}
    </div>
  </Flex>
);

const ProfileInsights = () => {
  return (
    <Card className="mb-3 opacity-25 d-none">
      <Card.Header>
        <Card.Title className="fw-bold fs-2">Insights</Card.Title>
      </Card.Header>
      <Card.Body className="pt-0">
        <Row className="g-3">
          {donationInfo?.map((info, index) => (
            <Col key={info.id} md={4}>
              <DonationData
                data={info}
                isLastItem={index === donationInfo.length - 1}
              />
            </Col>
          ))}
        </Row>
        <hr />
        <Row className="g-3">
          <Col md={12}>
            <p className="mb-0 fw-medium">
              <FontAwesomeIcon icon={'user'} className="me-1" />
              Giving Categories Snapshot
            </p>

            <Row className="g-3 mt-0">
              <Col md={9}>
                <div className="border-top">
                  <Table size="sm" className="mb-0">
                    <tbody>
                      {weDonorGivingData.map((item, index) => (
                        <TableRow
                          endToEnd={true}
                          key={item.label}
                          data={item}
                          isLastItem={index === weDonorGivingData?.length - 1}
                        />
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col md={3}>
                <div className="my-auto">
                  <ReactEChartsCore
                    echarts={echarts}
                    option={getOptions()}
                    style={{ height: 150 }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={12}>
            <Flex className={'mb-3'}>
              <div
                style={{ height: 32, width: 32 }}
                className="border rounded-circle d-flex align-items-center justify-content-center bg-soft-success"
              >
                <FontAwesomeIcon icon={'trophy'} className="text-success" />
              </div>
              <div className="ms-3">
                <p className="fw-semi-bold mb-0 text-800">
                  Donor Score and status
                </p>
                <p className="fw-semi-bold mb-0 text-1000">
                  Active Donor,{' '}
                  <span className="fw-medium text-800">
                    Among the top 32% within your orgniazation
                  </span>
                </p>
              </div>
            </Flex>
            <Flex>
              <div
                style={{ height: 32, width: 32 }}
                className="border rounded-circle d-flex align-items-center justify-content-center bg-soft-primary"
              >
                <FontAwesomeIcon icon={'envelope'} className="text-primary" />
              </div>
              <div className="ms-3">
                <p className="fw-semi-bold mb-0 text-800">Smart Ask</p>
                <p className="fw-medium mb-0">
                  This donor is most likely to donate between{' '}
                  <span className="fw-semi-bold text-1000">
                    $5,500 to $8,000.
                  </span>
                  We recommend asking for{' '}
                  <span className="fw-semi-bold text-primary">$7,000</span>
                </p>
              </div>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

DonationData.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    statusColor: PropTypes.string,
    info: PropTypes.string,
    lastDonation: PropTypes.string,
    notification: PropTypes.number,
    donationCount: PropTypes.number
  }),

  isLastItem: PropTypes.bool
};

export default ProfileInsights;
