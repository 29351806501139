import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFSelect } from 'components/common/form';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { GET_MOSQUE_LIST } from '../queries';

const schema = Yup.object().shape({
  mosque: Yup.string().required('Required Field')
});
function AddMosqueModal({ show, onSubmit, onHide, title, loading, mosques }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      mosque: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit } = methods;
  const [getMosque, { data: mosqueData }] = useLazyQuery(GET_MOSQUE_LIST);
  const { where } = useBranchPermission();
  useEffect(() => {
    getMosque({
      variables: { where }
    });
  }, [where]);

  const mosqueOptions = useMemo(() => {
    if (mosqueData?.mosques.length) {
      const options = [];
      mosqueData?.mosques.forEach(item => {
        const find = mosques.some(mosque => mosque.id === item.id);
        if (!find)
          options.push({
            label: item.name,
            value: item.id
          });
      });
      return options;
    }
  }, [mosqueData]);
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3 pe-3">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Flex gap={3} direction="column" className="mt-3">
              <Row>
                <Col md={12}>
                  <RHFSelect
                    name={'mosque'}
                    label={'Mosque'}
                    options={mosqueOptions}
                    required={true}
                  />
                </Col>
              </Row>
              <LoadingButton
                style={{ width: '100%', color: 'white' }}
                variant="primary"
                loading={loading}
                disabled={loading}
                className="w-100"
                type="submit"
              >
                {'Save'}
              </LoadingButton>
            </Flex>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

AddMosqueModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  onSubmit: PropTypes.func,
  mosques: PropTypes.array
};
export default AddMosqueModal;
