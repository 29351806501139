import DonarNav from 'components/donor-details/DonorNav';
import { DonorContextProvider } from 'context/donorContext';
import { getRelativePath } from 'helpers/utils';
import { useEffect, useRef, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import paths from 'routes/paths';
import DonorCommunications from './DonorCommunications';
import DonorFeedback from './DonorFeedback';
import DonorGiftAid from './DonorGiftAid';
import DonorGiving from './DonorGiving';
import DonorGivingDetails from './DonorGivingDetails';
import DonorProfile from './DonorProfile';
import DonorRelationManagement from './DonorRelationManagement';
import DonorSponsorships from './DonorSponsorships';
// import { useEffect, useRef } from 'react';

const DonorLayout = () => {
  const parentRef = useRef(null);
  const navRef = useRef(null);

  const [isWidthReady, setIsWidthReady] = useState(false);
  const [isHeightReady, setIsHeightReady] = useState(false);

  const [parentWidth, setParentWidth] = useState(0);
  const [navHeight, setNavHeight] = useState(0);

  useEffect(() => {
    if (!isWidthReady) return;
    setParentWidth(parentRef?.current?.clientWidth);
    if (!isHeightReady) return;
    console.log(navRef.current);
    setNavHeight(navRef?.current?.clientHeight);
  }, [isWidthReady, isHeightReady]);
  return (
    <DonorContextProvider>
      <div
        className="position-relative mb-5"
        style={{
          padding: '0px !important'
        }}
        ref={element => {
          parentRef.current = element;
          setIsWidthReady(true);
        }}
      >
        {/* <div className="position-absolute"> */}
        {parentWidth ? (
          <div
            id="top-bar"
            className="position-fixed "
            ref={element => {
              navRef.current = element;
              setIsHeightReady(true);
            }}
            style={{
              zIndex: 1,
              // width: document.getElementById('parent').clientHeight
              width: parentWidth
            }}
          >
            <DonarNav />
          </div>
        ) : null}
        {/* </div> */}

        <div
          style={{
            paddingTop: navHeight ?? 0
          }}
        >
          <Routes style={{ paddingTop: '100px' }}>
            <Route
              path={getRelativePath(paths.donorProfile, paths.donorDetails)}
              element={<DonorProfile />}
            />
            <Route
              path={getRelativePath(paths.donorGiving, paths.donorDetails)}
              element={<DonorGiving />}
            />
            <Route
              path={getRelativePath(
                paths.donorGivingDetails,
                paths.donorDetails
              )}
              element={<DonorGivingDetails />}
            />
            <Route
              path={getRelativePath(
                paths.donorSponsorships,
                paths.donorDetails
              )}
              element={<DonorSponsorships />}
            />
            <Route
              path={getRelativePath(
                paths.donorCommunications,
                paths.donorDetails
              )}
              element={<DonorCommunications />}
            />
            <Route
              path={getRelativePath(
                paths.donorRelationManagement,
                paths.donorDetails
              )}
              element={<DonorRelationManagement />}
            />
            <Route
              path={getRelativePath(paths.donorGiftAid, paths.donorDetails)}
              element={<DonorGiftAid />}
            />
            <Route
              path={getRelativePath(paths.donorFeedback, paths.donorDetails)}
              element={<DonorFeedback />}
            />

            <Route
              path="*"
              element={
                <Navigate
                  to={getRelativePath(paths.donorProfile, paths.donorDetails)}
                  replace
                />
              }
            />
          </Routes>
        </div>
      </div>
    </DonorContextProvider>
  );
};

export default DonorLayout;
