import { useState, useRef, useEffect, useContext, useMemo } from 'react';
import { Card, Col, Row, Nav } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import { ChecksOptionsCard } from 'components/common/ChecksOptionsCard';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { parseISO } from 'date-fns';

import { FormProvider, RHFControl } from 'components/common/form';
import { ProfileInformation } from './ProfileInformation';
import { LocationCard } from './LocationCard';
import { GuardianDetails } from './GuardianDetails';
import { InspirationCard } from './InspirationCard';
import { SponsorshipStatus } from './SponsorshipStatus';
import {
  getCitiesOptions,
  getCountriesOptions
} from 'data/options/countris-and-cities';
import {
  GET_SPONSORSHIP_CONDITIONS,
  GET_SPONSORSHIP_COST,
  GET_SPONSORSHIP_TYPE
} from './beneficiary-options-queries';
import AuthContext from 'context/authContext';
import LoadingButton from 'components/common/LoadingButton';
import { calculateAge } from 'helpers/utils';
import { beneficiaryScheme } from './beneficiaryScheme';
import { getTrueKeys } from 'components/donations/donation-packages/package-form/PackageForm';
import useUpload from 'hooks/useUpload';
import { toast } from 'react-toastify';

const SPONSORSHIP_DURATION_MONTHLY = 'monthly';
const SPONSORSHIP_DURATION_ANNUALLY = 'annually';

export const BENEFICIARY_STATUS_SPONSORED = 'sponsored';
export const BENEFICIARY_STATUS_UNSPONSORED = 'unsponsored';

const BeneficiaryForm = ({ onSubmit, isEdit, data, loading }) => {
  const { organization, selectedBranchId } = useContext(AuthContext);
  const { data: conditionsData } = useQuery(GET_SPONSORSHIP_CONDITIONS, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });

  const { data: costData } = useQuery(GET_SPONSORSHIP_COST, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });
  const { data: typeData } = useQuery(GET_SPONSORSHIP_TYPE, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  const sideNavLinks = [
    { id: 1, href: '#info', title: 'Profile Information' },
    { id: 2, href: '#sponsorshipType', title: 'Sponsorship Type' },
    { id: 3, href: '#location', title: 'Location' },
    { id: 4, href: '#condition', title: 'Health Condition' },
    { id: 5, href: '#details', title: 'Guardian Details' },
    { id: 6, href: '#inspiration', title: 'Inspiration' },
    { id: 7, href: '#duration', title: 'Sponsorship Duration' },
    { id: 8, href: '#sponsorshipCost', title: 'Sponsorship Cost' },
    {
      id: 9,
      href: '#sponsorshipStatus',
      title: 'Sponsorship Status'
    }
  ];
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      gender: '',
      dateOfBirth: '',
      age: '',
      language: '',
      status: '',
      priority: '',
      type: '',
      image: '',
      guardian: {
        fatherStatus: '',
        motherStatus: '',
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        phone: '',
        relationship: ''
      },
      healthCondition: '',
      healthNotes: '',
      cost: {},
      location: {
        country: '',
        city: '',
        condition: ''
      },
      inspiration: '',
      duration: {
        [SPONSORSHIP_DURATION_MONTHLY]: false,
        [SPONSORSHIP_DURATION_ANNUALLY]: false
      }
    },
    resolver: yupResolver(beneficiaryScheme)
  });
  const { handleSubmit: RHFHandleSubmit, watch, setValue, getValues } = methods;
  const values = getValues();
  const {
    location: { country },
    dateOfBirth
  } = watch();
  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);
  const conditionsRadios = useMemo(
    () =>
      conditionsData?.sponsorship_condition?.map(({ condition, id }) => {
        // if (isEdit) {
        //   const itemExists = data.location?.find(
        //     location_id => location_id === location.id
        //   );
        //   setValue(`locations.${location.id}`, itemExists ? true : false);
        // } else {
        //   setValue(`locations.${location.id}`, false);
        // }
        return {
          name: 'healthCondition',
          label: condition,
          value: id
        };
      }),
    [conditionsData]
  );
  const typesRadios = useMemo(
    () =>
      typeData?.sponsorship_type?.map(({ type, id }) => {
        return {
          name: 'type',
          label: type,
          value: id
        };
      }),
    [typeData]
  );

  const costCheckboxes = useMemo(
    () =>
      costData?.sponsorship_cost?.map(cost => {
        if (isEdit) {
          const itemExists = data.cost?.find(cost_id => cost_id === cost.id);
          setValue(`cost.${cost.id}`, itemExists ? true : false);
        } else {
          setValue(`cost.${cost.id}`, false);
        }
        return {
          name: `cost.${cost.id}`,
          label: `$${cost.amount.toFixed(2)}`
        };
      }),
    [costData]
  );

  useEffect(() => {
    const age = dateOfBirth ? calculateAge(dateOfBirth) : '';
    setValue('age', age);
  }, [dateOfBirth]);

  useEffect(() => {
    if (isEdit) {
      const {
        DOB,
        first_name,
        middle_name,
        last_name,
        gender,
        language,
        country,
        city,
        location_condition,
        sponsorship_condition_id,
        status,
        health_notes,
        inspiration,
        duration,
        sponsorship_type_id,
        sponsorship_priority_id,
        image,
        guardian
      } = data;
      setValue('firstName', first_name);
      setValue('middleName', middle_name);
      setValue('lastName', last_name);
      const locationValues = {
        country,
        city,
        condition: location_condition
      };
      setValue('location', locationValues);
      const guardianValues = {
        fatherStatus: guardian.father_status,
        motherStatus: guardian.mother_status,
        firstName: guardian.first_name,
        middleName: guardian.middle_name,
        lastName: guardian.last_name,
        email: guardian.email,
        phone: guardian.phone,
        relationship: guardian.relationship
      };
      setValue('guardian', guardianValues);
      setValue('gender', gender);
      setValue('language', language);
      setValue('status', status);
      setValue('healthNotes', health_notes);
      setValue('inspiration', inspiration);
      setValue('healthCondition', sponsorship_condition_id);
      setValue('type', sponsorship_type_id);
      setValue('priority', sponsorship_priority_id);
      setValue('image', image);
      setValue('dateOfBirth', parseISO(DOB));
      duration?.forEach(id => {
        // const itemExists = duration.find(
        //   payment_option_id => payment_option_id === id
        // );
        setValue(`duration.${id}`, true);
      });
    }
  }, [data]);

  const handleRadioChange = e => {
    const { value, name } = e.target;
    setValue(name, value);
  };
  const { upload, loading: uploadLoading } = useUpload();

  const handleSubmit = async data => {
    try {
      if (data.image && typeof data.image !== 'string') {
        const profileUrl = await upload(data.image);
        data.image = profileUrl;
      }
    } catch (e) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }

    const {
      firstName,
      middleName,
      lastName,
      gender,
      dateOfBirth,
      // age,
      language,
      status,
      priority,
      type,
      guardian,
      healthCondition,
      healthNotes,
      cost,
      location,
      inspiration,
      duration,
      image
    } = data;
    const formattedDateOfBirth = new Date(dateOfBirth).toISOString();
    const costOptionsData = getTrueKeys(cost);
    const durationOptionsData = getTrueKeys(duration);
    const submitData = {
      DOB: formattedDateOfBirth,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      gender,
      language,
      country: location.country,
      city: location.city,
      location_condition: location.condition,
      sponsorship_condition_id: healthCondition,
      status: status,
      health_notes: healthNotes,
      inspiration,
      cost: costOptionsData,
      duration: durationOptionsData,
      sponsorship_type_id: type,
      sponsorship_priority_id: priority,
      image: image,
      guardian: {
        email: guardian.email,
        father_status: guardian.fatherStatus,
        first_name: guardian.firstName,
        last_name: guardian.lastName,
        middle_name: guardian.middleName,
        mother_status: guardian.motherStatus,
        phone: guardian.phone,
        relationship: guardian.relationship
      },
      branch_id: selectedBranchId,
      organisation_id: organization.id
    };
    onSubmit(submitData);
  };

  return (
    <FormProvider methods={methods} onSubmit={RHFHandleSubmit(handleSubmit)}>
      <Row className="pt-3 gx-3">
        <Col md={9}>
          <ProfileInformation
            sectionRef={sectionRefs[1]}
            sideNavLink={sideNavLinks[0]?.href?.substring(1)}
          />
          <ChecksOptionsCard
            error={values.type === '' ? 'required Field' : ''}
            sectionRef={sectionRefs[2]}
            sideNavLink={sideNavLinks[1]?.href?.substring(1)}
            card={{
              title: 'Sponsorship Type',
              value: '',
              check: true,
              isEdit: false,
              switch: false,
              checkType: 'radio',
              checkboxes: typesRadios,
              handleChange: handleRadioChange
            }}
          />
          <LocationCard
            sectionRef={sectionRefs[3]}
            sideNavLink={sideNavLinks[2]?.href?.substring(1)}
            countriesOptions={countriesOptions}
            citiesOptions={citiesOptions}
          />
          <ChecksOptionsCard
            error={values.healthCondition === '' ? 'required Field' : ''}
            sectionRef={sectionRefs[4]}
            sideNavLink={sideNavLinks[3]?.href?.substring(1)}
            card={{
              title: 'Health Condition',
              value: '',
              check: true,
              isEdit: false,
              switch: false,
              checkType: 'radio',
              checkboxes: conditionsRadios,
              handleChange: handleRadioChange
            }}
          >
            <RHFControl label="Health Notes" name="healthNotes" />
          </ChecksOptionsCard>
          <GuardianDetails
            sectionRef={sectionRefs[5]}
            sideNavLink={sideNavLinks[4]?.href?.substring(1)}
          />
          <InspirationCard
            sectionRef={sectionRefs[6]}
            sideNavLink={sideNavLinks[5]?.href?.substring(1)}
          />
          <ChecksOptionsCard
            error={Object.keys(values.duration).every(key => {
              if (values.duration[key] === true) {
                return '';
              } else {
                return 'Required Field';
              }
            })}
            sectionRef={sectionRefs[7]}
            sideNavLink={sideNavLinks[6]?.href?.substring(1)}
            card={{
              title: 'Sponsorship Duration',
              value: '',
              check: true,
              isEdit: false,
              switch: false,
              checkboxes: [
                {
                  name: `duration.${SPONSORSHIP_DURATION_MONTHLY}`,
                  label: 'Monthly'
                },
                {
                  name: `duration.${SPONSORSHIP_DURATION_ANNUALLY}`,
                  label: 'Annually'
                }
              ]
            }}
          />
          <ChecksOptionsCard
            error={Object.keys(values.cost).every(key => {
              if (values.cost[key] === true) {
                return '';
              } else {
                return 'Required Field';
              }
            })}
            sectionRef={sectionRefs[8]}
            sideNavLink={sideNavLinks[7]?.href?.substring(1)}
            card={{
              title: 'Sponsorship Cost',
              value: '',
              check: true,
              isEdit: false,
              switch: false,
              checkboxes: costCheckboxes
            }}
          />
          <SponsorshipStatus
            sectionRef={sectionRefs[8]}
            sideNavLink={sideNavLinks[8]?.href?.substring(1)}
          />
          <Card className="border shadow-none mb-3">
            <Card.Body className="d-flex flex-row justify-content-end">
              <LoadingButton
                loading={loading || uploadLoading}
                type="submit"
                className="w-100 w-sm-25"
              >
                {isEdit ? 'Update' : 'Add'}
              </LoadingButton>
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="order-0 order-md-1 d-none d-md-block">
          <Card
            className={'mb-3 sticky-top border shadow-none'}
            style={{ top: stickyStatus ? '5rem' : '0' }}
          >
            <Card.Body>
              <div>
                <Nav className="flex-column" variant="pills">
                  {sideNavLinks?.map(item => (
                    <Nav.Link
                      key={item.id}
                      href={item.href}
                      className={`${
                        activeSection === item.id
                          ? 'current custom-nav-link'
                          : 'custom-nav-link'
                      }`}
                      active={activeSection >= item.id}
                    >
                      {item.title}
                    </Nav.Link>
                  ))}
                </Nav>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </FormProvider>
  );
};

BeneficiaryForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  data: PropTypes.object
};

export default BeneficiaryForm;
