import { useForm, FormProvider } from 'react-hook-form';
import { useState, useContext, useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import * as Yup from 'yup';
import ADAdvancedSettings from './donation-form-components/ADAdvancedSettings';
import ADDonationDetails from './donation-form-components/ADDonationDetails';
import ADSelectDonor from './donation-form-components/ADSelectDonor';
import ADSelectedAppeals from './donation-form-components/ADSelectedAppeals';
import {
  FEE_TYPE_FIXED,
  FEE_TYPE_PERCENTAGE,
  GET_TRANSACTION_FEES
} from 'components/donations/donation-settings/TransactionFees';
import AuthContext from 'context/authContext';
import LoadingButton from 'components/common/LoadingButton';
import { toast } from 'react-toastify';

export const calculatePercentageFee = (amount, percentage) => {
  return (amount * percentage) / 100;
};

const DonationConfig = ({ onSubmit, loading }) => {
  const { organization } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [selectedAppeal, setSelectedAppeal] = useState();
  const [index, setIndex] = useState();
  const [donationItems, setDonationItems] = useState([]);
  const { data: transactionFeesData } = useQuery(GET_TRANSACTION_FEES, {
    variables: {
      organisation_id: organization.id
    }
  });
  const donorId = searchParams.get('donorId');
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      donorId: '',
      settings: {
        coverTransactionFee: false,
        giftAid: false
      }
    },
    resolver: yupResolver(
      Yup.object().shape({
        donorId: Yup.string().required('Required Field')
      })
    )
  });

  const { handleSubmit: RHFHandleSubmit, setValue, watch } = methods;
  const coverTransactionFee = watch('settings.coverTransactionFee');
  useEffect(() => {
    if (donorId) {
      setValue('donorId', donorId);
    }
  }, [donorId, setValue]);

  const handleSubmit = data => {
    if (donationItems.length === 0) {
      toast.error('Please add a package');
    } else
      onSubmit({
        ...data,
        donationItems,
        subtotal: totalDonationAmount,
        transactionFees: totalTransactionFee,
        total: totalDonationAmount + totalTransactionFee
      });
  };

  const totalDonationAmount = useMemo(() => {
    return donationItems.reduce((acc, curr) => {
      acc += curr.amount;
      return acc;
    }, 0);
  }, [donationItems]);
  const totalTransactionFee = useMemo(() => {
    if (!transactionFeesData) return 0;
    if (coverTransactionFee) {
      const total = transactionFeesData.transaction_fee.reduce((acc, curr) => {
        if (curr.fee_type === FEE_TYPE_PERCENTAGE) {
          acc = calculatePercentageFee(totalDonationAmount, curr.amount);
        } else if (curr.fee_type === FEE_TYPE_FIXED) {
          acc += curr.amount;
        }
        return acc;
      }, 0);
      return total;
    }
    return 0;
  }, [transactionFeesData, totalDonationAmount, coverTransactionFee]);

  const onAddNewDonationItem = data => {
    if (index === undefined) {
      setDonationItems(prevState => {
        const newState = [...prevState];
        newState.push(data);
        return newState;
      });
    } else {
      setDonationItems(prevState => {
        const newState = [...prevState];
        newState[index] = data;
        return newState;
      });
      setIndex(null);
      setSelectedAppeal(null);
    }
  };

  const onRemoveDonationItem = index => {
    setDonationItems(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const onEditDonationItemClick = index => {
    setSelectedAppeal(donationItems[index]);
    setIndex(index);
  };

  return (
    <div className={'py-0 mx-auto'} style={{ maxWidth: '820px' }}>
      <FormProvider {...methods}>
        <ADSelectDonor />
        <ADDonationDetails
          data={selectedAppeal?.values}
          onSubmit={onAddNewDonationItem}
        />
        <ADSelectedAppeals
          items={donationItems}
          total={
            totalDonationAmount +
            (coverTransactionFee ? totalTransactionFee : 0)
          }
          transactionFee={coverTransactionFee ? totalTransactionFee : 0}
          onEdit={onEditDonationItemClick}
          onRemove={onRemoveDonationItem}
        />
        <ADAdvancedSettings transactionFee={totalTransactionFee} />
        {/* <ADScheduleDonations /> */}
        <LoadingButton
          // type="submit"
          // onClick={() => navigate(paths.payNow)}
          loading={loading}
          size="lg"
          variant="success"
          className="w-100 py-3 mt-3 fs-0 fs-md-1"
          onClick={RHFHandleSubmit(handleSubmit)}
        >
          Save And Continue To Payments
        </LoadingButton>
      </FormProvider>
    </div>
  );
};

DonationConfig.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default DonationConfig;
