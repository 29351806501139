import TableRowAction from 'components/common/TableRowAction';
import React, { useEffect, useState } from 'react';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import SelectStudentModal from '../school/SelectStudentModal';

import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import OrphanageForm from './OrphanageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_BENEFICIARY_BY_ID,
  GET_ORPHANAGE,
  GET_ORPHANAGE_ORPHAN,
  GET_ORPHAN_BY_ORPHANAGE
} from '../queries';
import {
  DELETE_ORPHANAGE_ORPHAN,
  INSERT_ORPHANAGE_ORPHAN,
  UPDATE_ORPHANAGE
} from '../mutations';
import { toast } from 'react-toastify';

function EditOrphanage() {
  const schoolColumns = [
    {
      accessor: 'first_name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { first_name } = rowData.row.original;
        return <>{first_name}</>;
      }
    },
    {
      accessor: 'DOB',
      Header: 'Age',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { DOB } = rowData.row.original;
        return <>{dayjs(DOB).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender'
    },
    {
      accessor: 'created_at',
      Header: 'Registration',
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'inspiration',
      Header: 'Aspiration'
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickDelete={() => removeStudent(id)}
            isNotEdit
            subject={permissionsKeyMap.sponsorship.children.orphanage}
          />
        );
      }
    }
  ];

  const { id } = useParams();
  const navigate = useNavigate();

  const { data: orphanageData } = useQuery(GET_ORPHANAGE, {
    variables: {
      id
    }
  });

  const { data: orphanageListData } = useQuery(GET_ORPHANAGE_ORPHAN, {
    fetchPolicy: 'network-only',
    variables: {
      id
    }
  });
  const [deleteOrphangeOrphan] = useMutation(DELETE_ORPHANAGE_ORPHAN);

  const [getOrphansById, { data: dataOrphansById }] = useLazyQuery(
    GET_ORPHAN_BY_ORPHANAGE
  );
  useEffect(() => {
    if (orphanageListData?.orphan_orphanage.length) {
      const orphanIds = orphanageListData.orphan_orphanage.map(
        item => item.orphan_id
      );
      getOrphansById({
        variables: {
          _in: orphanIds
        }
      });
    }
  }, [orphanageListData]);

  const [showStudentAddModal, setShowStudentAddModal] = useState(false);

  const [getBeneficiaryById, { data: dataBeneficiary }] = useLazyQuery(
    GET_BENEFICIARY_BY_ID
  );

  useEffect(() => {
    if (dataBeneficiary?.orphans_by_pk) {
      setListOfStudents([...listOfStudents, dataBeneficiary?.orphans_by_pk]);
    }
  }, [dataBeneficiary]);

  const submitStudent = value => {
    getBeneficiaryById({
      variables: {
        id: value
      }
    });
  };
  const [listOfStudents, setListOfStudents] = useState([]);

  const submitAddMosque = data => {
    updateOrphanage({
      variables: {
        id: id,
        location_id: data.locationId,
        name: data.name
      }
    });
  };

  useEffect(() => {
    if (dataOrphansById?.orphans) {
      const { orphans } = dataOrphansById;

      setListOfStudents([...listOfStudents, ...orphans]);
    }
  }, [dataOrphansById]);

  const removeStudent = id => {
    setListOfStudents(listOfStudents.filter(item => item.id !== id));
  };

  const [addOrphanageOrphan] = useMutation(INSERT_ORPHANAGE_ORPHAN, {
    onCompleted: () => {
      toast.success('Orphans Added Successfully');
      navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  const [updateOrphanage] = useMutation(UPDATE_ORPHANAGE, {
    refetchQueries: [
      'GetOrphanage',
      'GetBeneficiaryByID',
      'GetOrphanByOrphanage'
    ],
    onCompleted: () => {
      toast.success('Orphans Added Successfully');
      const tmp = listOfStudents.map(item => {
        return {
          orphanage_id: id,
          orphan_id: item.id
        };
      });
      deleteOrphangeOrphan({ variables: { id } }).then(() => {
        if (tmp.length !== 0)
          addOrphanageOrphan({ variables: { objects: tmp } });
        else navigate(-1);
      });

      // navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  return (
    <>
      <OrphanageForm
        onSubmit={submitAddMosque}
        loading={false}
        initValues={orphanageData?.orphanages_by_pk || {}}
      />
      <SelectStudentModal
        show={showStudentAddModal}
        onHide={() => setShowStudentAddModal(false)}
        onSubmit={submitStudent}
      />
      <Row className="mt-3">
        <Col md={12} xxl={12} className="h-md-100">
          {listOfStudents && (
            <CustomAdvanceTable
              data={listOfStudents}
              columns={schoolColumns}
              title="Orphans"
              addButtonLabel="Add Orphan"
              addButtonIcon="user"
              onClickAddNew={() => {
                setShowStudentAddModal(true);
              }}
              addNew
              subject={permissionsKeyMap.sponsorship.children.school}
              bulkActionsOptions
              handleBulkAction
              selection={true}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default EditOrphanage;
