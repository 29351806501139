import { RHFControl, RHFPhoneInput, RHFSelect } from 'components/common/form';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const GuardianDetails = ({ sectionRef, sideNavLink }) => {
  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Guardian Details</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Container className="p-0 d-flex flex-column g-3">
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFSelect
                name="guardian.fatherStatus"
                label="Father's Status"
                required
                hasDummyOptions
              />
            </Col>
            <Col xs={12} sm={6}>
              <RHFSelect
                name="guardian.motherStatus"
                label="Mother's Status"
                required
                hasDummyOptions
              />
            </Col>
          </Row>
          <hr />
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFControl
                name="guardian.firstName"
                label="First Name"
                required
              />
            </Col>
            <Col xs={12} sm={6}>
              <RHFControl
                name="guardian.middleName"
                label="Middle Name"
                required
              />
            </Col>
          </Row>
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFControl name="guardian.lastName" label="Last Name" required />
            </Col>
            <Col xs={12} sm={6}>
              <RHFSelect
                name="guardian.relationship"
                label="RelationShip"
                required
                hasDummyOptions
              />
            </Col>
          </Row>
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFPhoneInput
                name="guardian.phone"
                label="Contact Number"
                required
              />
            </Col>
            <Col xs={12} sm={6}>
              <RHFControl name="guardian.email" label="Contact Email" />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

GuardianDetails.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
