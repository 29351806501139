import { gql } from '@apollo/client';

export const GET_SPONSORSHIP_CONDITIONS = gql`
  query GetSponsorshipConditions($organizationId: uuid = "") {
    sponsorship_condition(
      where: { organisation_id: { _eq: $organizationId } }
    ) {
      condition
      id
    }
  }
`;

export const GET_SPONSORSHIP_PRIORITY = gql`
  query GetSponsorshipPriority {
    sponsorship_priority {
      priority_type
      id
    }
  }
`;

export const GET_SPONSORSHIP_STATUS = gql`
  query GetSponsorshipStatus($organizationId: uuid = "", $branchId: uuid = "") {
    sponsorship_status(where: { organisation_id: { _eq: $organizationId } }) {
      status
      id
    }
  }
`;

export const GET_SPONSORSHIP_COST = gql`
  query GetSponsorshipCost($organizationId: uuid = "", $branchId: uuid = "") {
    sponsorship_cost(where: { organisation_id: { _eq: $organizationId } }) {
      amount
      id
    }
  }
`;

export const GET_SPONSORSHIP_TYPE = gql`
  query GetSponsorshipType($organizationId: uuid = "", $branchId: uuid = "") {
    sponsorship_type(where: { organisation_id: { _eq: $organizationId } }) {
      type
      id
    }
  }
`;
