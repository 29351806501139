import { useContext, useMemo } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RHFSelect } from 'components/common/form';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import { GET_SPONSORSHIP_PRIORITY } from './beneficiary-options-queries';
import {
  BENEFICIARY_STATUS_SPONSORED,
  BENEFICIARY_STATUS_UNSPONSORED
} from './BeneficiaryForm';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';

export const SponsorshipStatus = ({ sectionRef, sideNavLink }) => {
  const { organization } = useContext(AuthContext);
  const { data: priorityData, loading: priorityLoading } = useQuery(
    GET_SPONSORSHIP_PRIORITY,
    {
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organization.id
      }
    }
  );
  // const { data: statusData, loading: statusLoading } = useQuery(
  //   GET_SPONSORSHIP_STATUS,
  //   {
  //     variables: {
  //       organizationId: organization.id,
  //       branchId: branch.id
  //     }
  //   }
  // );
  // const statusOptions = useMemo(
  //   () =>
  //     statusData?.sponsorship_status?.map(status => ({
  //       value: status.id,
  //       label: status.status
  //     })),
  //   [statusData]
  // );

  const priorityOptions = useMemo(
    () =>
      priorityData?.sponsorship_priority?.map(priority => ({
        value: priority.id,
        label: priority.priority_type
      })),
    [priorityData]
  );
  const sponsorshipStatusOptions = [
    {
      label: 'Sponsored',
      value: BENEFICIARY_STATUS_SPONSORED
    },
    {
      label: 'Unsponsored',
      value: BENEFICIARY_STATUS_UNSPONSORED
    }
  ];
  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Sponsorship Status</Card.Title>
          <Button
            variant="light"
            className="shadow-none bg-transparent border-0"
            style={{
              fontSize: 13
            }}
            as={Link}
            to={paths.sponsorshipValues}
          >
            <FontAwesomeIcon icon="pen" style={{ marginRight: '5px' }} />
            Edit
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <Row className="gx-3">
          <Col xs={12} sm={6} className="pt-3">
            <RHFSelect
              name="status"
              label="Status"
              required
              options={sponsorshipStatusOptions}
            />
          </Col>
          <Col xs={12} sm={6} className="pt-3">
            <RHFSelect
              name="priority"
              label="Priority"
              required
              options={priorityOptions}
              loading={priorityLoading}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

SponsorshipStatus.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
