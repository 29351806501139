import * as Yup from 'yup';

export const beneficiaryScheme = Yup.object().shape({
  firstName: Yup.string().required('Required Field'),
  middleName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  gender: Yup.string().required('Required Field'),
  dateOfBirth: Yup.string().required('Required Field'),
  language: Yup.string(),
  status: Yup.string().required('Required Field'),
  priority: Yup.string().required('Required Field'),
  type: Yup.string().required('Required Field'),
  guardian: Yup.object().shape({
    email: Yup.string().email('Invalid Email'),
    fatherStatus: Yup.string().required('Required Field'),
    firstName: Yup.string().required('Required Field'),
    lastName: Yup.string().required('Required Field'),
    middleName: Yup.string().required('Required Field'),
    motherStatus: Yup.string().required('Required Field'),
    phone: Yup.string().required('Required Field'),
    relationShip: Yup.string()
  }),
  healthCondition: Yup.string().required('Required Field'),
  healthNotes: Yup.string(),
  // cost: Yup.string().required('Required Field'),
  location: Yup.object().shape({
    city: Yup.string().required('Required Field'),
    condition: Yup.string().required('Required Field'),
    country: Yup.string().required('Required Field')
  }),
  inspiration: Yup.string()
  // sponsorshipDuration: Yup.string(),
});
