import { useLazyQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { GET_LOCATION } from '../queries';

/* -------------------------------------------------------------------------- */

export const hospitalSchema = Yup.object().shape({
  locationId: Yup.string().required('Required Field'),
  name: Yup.string().required('Required Field')
});
/* -------------------------------------------------------------------------- */
export default function HospitalForm({ onSubmit, initValues, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(hospitalSchema)
  });
  const { where } = useBranchPermission();

  const [getLocation, { data: locationData }] = useLazyQuery(GET_LOCATION);
  useEffect(() => {
    if (Object.keys(where).length)
      getLocation({
        variables: { where }
      });
  }, [where]);

  const locationOptions = useMemo(() => {
    if (locationData)
      return locationData.location.map(loc => {
        return { label: loc.location_name, value: loc.id };
      });
  }, [locationData]);

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (initValues) {
      reset({
        locationId: initValues.location_id,
        name: initValues.name
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initValues]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header>
            <Card.Title className="fw-medium">Primary Information</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Row className="mb-3 g-3">
              <Col md={6}>
                <RHFControl
                  name="name"
                  placeholder="Name"
                  label="Name"
                  required
                />
              </Col>

              <Col md="6">
                <RHFSelect
                  name="locationId"
                  placeholder="Location"
                  label="Location"
                  required
                  options={locationOptions}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {/* </FormProvider> */}
        <Card>
          <Card.Body>
            <Flex justifyContent={'between'} className="align-items-center">
              <p className="mb-0 fs-2 fw-medium">
                {initValues ? 'Update Hospital' : 'Create Hospital'}
              </p>
              <LoadingButton
                loading={loading}
                type="submit"
                className="px-5 py-2"
              >
                Submit
              </LoadingButton>
            </Flex>
          </Card.Body>
        </Card>
      </FormProvider>
    </>
  );
}

HospitalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.func.object,
  loading: PropTypes.bool
};
