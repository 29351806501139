import React, { useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
// import ImageGrid from './ImageGrid';

import { useMutation, useQuery } from '@apollo/client';
import { faBorderAll, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { useAuthContext } from 'context/authContext';
import useUpload from 'hooks/useUpload';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADD_GALLERY, DELETE_GALLERY_IMAGE } from '../mutations';
import { GET_GALLERY } from '../queries';
import AddImageModal from './AddImageModal';
import ImageGrid from './ImageGrid';
import ImageList from './ImageList';
import { imageData } from './imageData';

function Gellery() {
  const isList = 'list';
  const isGrid = 'grid';
  const [layout, setLayout] = useState('list');
  const [addImageShow, setAddImageShow] = useState(false);
  const { upload, loading: uploadLoading } = useUpload();
  const { organization, selectedBranchId, user } = useAuthContext();
  const { id } = useParams();

  const [addGallery] = useMutation(ADD_GALLERY, {
    onCompleted: () => {
      toast.success('Image Added Successfully');
      setAddImageShow(false);
    },
    onError: error => {
      toast.error('Something went wrong! Please Try again later!');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingGallery = cache.readQuery({
        query: GET_GALLERY,
        variables: {
          id
        }
      });
      if (!existingGallery) return;
      cache.writeQuery({
        query: GET_GALLERY,
        variables: {
          id
        },
        data: {
          gallery: [...existingGallery.gallery, data.insert_gallery_one]
        }
      });
    }
  });

  const [deleteGallery] = useMutation(DELETE_GALLERY_IMAGE, {
    onCompleted: () => {
      toast.success('Image Deleted Successfully');
      setAddImageShow(false);
    },
    onError: error => {
      toast.error('Something went wrong! Please Try again later!');
      console.log(error);
    },
    update: cache => {
      const existingGallery = cache.readQuery({
        query: GET_GALLERY,
        variables: {
          id
        }
      });
      if (!existingGallery) return;

      cache.writeQuery({
        query: GET_GALLERY,
        variables: {
          id
        },
        data: {
          gallery: [
            ...existingGallery.gallery.filter(item => item.id !== selectedId)
          ]
        }
      });
    }
  });

  const onSubmit = async values => {
    try {
      if (values.image && typeof values.image !== 'string') {
        const profileUrl = await upload(values.image);
        values.image = profileUrl;
        addGallery({
          variables: {
            branch_id: selectedBranchId,
            gallery_image: values.image,
            organisation_id: organization.id,
            orphan_id: id,
            user_id: user.id,
            image_description: values.description
          }
        });
      }
    } catch (error) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
  };

  const { data: galleryDate } = useQuery(GET_GALLERY, {
    variables: {
      id
    }
  });
  const [viewAll, setViewAll] = useState(false);
  const [limit, setLimit] = useState(8);
  const [selectedId, setSelectedId] = useState();
  const [showDeleteImageModal, setShowDeleteImageModal] = useState(false);
  const [globalFilter, setGlobalFilter] = useState('');
  const handleDeleteImageHide = () => {
    setShowDeleteImageModal(false);
  };
  const handleConfirmDelete = () => {
    deleteGallery({
      variables: {
        id: selectedId
      }
    });
    setShowDeleteImageModal(false);
  };
  const data = useMemo(() => {
    if (galleryDate?.gallery) {
      if (globalFilter !== '') {
        return galleryDate?.gallery.filter(item => {
          return item.image_description.includes(globalFilter);
        });
      } else return galleryDate?.gallery;
    } else return [];
  }, [galleryDate, globalFilter]);
  return (
    <>
      <ConfirmationModal
        show={showDeleteImageModal}
        onHide={handleDeleteImageHide}
        title={'Delete Image'}
        handleConfirm={handleConfirmDelete}
        body={'Are you sure you want to remove selected Image?'}
      />
      <Card>
        <Card.Header className="h4">Gallery</Card.Header>
      </Card>
      <Card className="mt-3">
        <Card.Header className="d-flex justify-content-between">
          <div className="text-nowrap h4">Photo List</div>
          <Button
            variant="falcon-default"
            size="md"
            onClick={() => {
              setAddImageShow(true);
            }}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body
          className={classNames('ps-3', {
            'p-0 overflow-hidden': isList,
            'pb-0': isGrid
          })}
        >
          <Row className="gx-2 align-items-center p-2">
            <Col md={9}>
              <AdvanceTableSearchBox
                className="table-input-search-width w-75"
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                // placeholder={searchInputPlaceHolder}
                showSearchIcon={false}
              />
            </Col>
            <Col
              md={3}
              className="align-middle d-flex justify-content-evenly mt-2"
            >
              <p>View: </p>
              <FontAwesomeIcon
                icon={faList}
                role="button"
                className={classNames({
                  'text-primary': layout === 'layout'
                })}
                onClick={() => {
                  setLayout('list');
                }}
              />
              <FontAwesomeIcon
                icon={faBorderAll}
                role="button"
                className={classNames({
                  'text-primary': layout === 'grid'
                })}
                onClick={() => {
                  setLayout('grid');
                }}
              />
            </Col>
          </Row>
          <Row
            className={classNames({
              'g-0': isList
            })}
          >
            {data.slice(0, limit).map((image, index) =>
              layout === 'list' ? (
                <>
                  <ImageList
                    ImageObj={image}
                    key={image.id}
                    index={index}
                    setSelectedId={setSelectedId}
                    setShowDeleteImageModal={setShowDeleteImageModal}
                  />
                </>
              ) : (
                <>
                  <ImageGrid
                    ImageObj={image}
                    key={image.id}
                    index={index}
                    setSelectedId={setSelectedId}
                    setShowDeleteImageModal={setShowDeleteImageModal}
                  />
                </>
              )
            )}
          </Row>
          <hr />
          <div
            className="d-flex text-primary"
            onClick={() => {
              {
                !viewAll ? setLimit(imageData.length) : setLimit(5);
              }
              setViewAll(!viewAll);
            }}
          >
            <FontAwesomeIcon
              icon={viewAll ? 'chevron-up' : 'chevron-down'}
              size="sm"
              className="mt-1 me-2"
            />
            <p> {viewAll ? 'View Less' : 'View More'}</p>
          </div>
        </Card.Body>
      </Card>
      <AddImageModal
        show={addImageShow}
        onSubmit={onSubmit}
        onHide={() => {
          setAddImageShow(false);
        }}
        loading={uploadLoading}
        title={'Add Photo'}
      />
    </>
  );
}

export default Gellery;
