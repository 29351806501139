import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Section from 'components/common/Section';
import AuthContext from 'context/authContext';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { GET_DONORS } from './donation-form-components/ADSelectDonor';
import DonorCard from './donation-form-components/DonorCard';
import PNDonationSummary from './donation-form-components/PNDonationSummary';
import PNPaymentOption from './donation-form-components/PNPaymentOption';

export const GET_PAYMENT_METHODS = gql`
  mutation GetPaymentMEthod($donor_id: ID = "") {
    GetPaymentMethods(donor_id: $donor_id) {
      payment_methods {
        id
        card {
          exp_month
          exp_year
          last4
          brand
        }
      }
    }
  }
`;

export const ATTACH_PAYMENT_METHOD = gql`
  mutation AttachPaymentMethod(
    $donor_id: ID = ""
    $paymentMethodId: String = ""
  ) {
    AttachPaymentMethod(
      donor_id: $donor_id
      paymentMethodId: $paymentMethodId
    ) {
      message
    }
  }
`;

export const MAKE_PAYMENT_METHOD_DEFAULT = gql`
  mutation MakePaymentMethodDefault(
    $donor_id: ID = ""
    $paymentMethodId: String = ""
  ) {
    MakePaymentMethodDefault(
      donor_id: $donor_id
      paymentMethodId: $paymentMethodId
    ) {
      message
    }
  }
`;

const DonationPayment = ({ data, onSubmit, loading }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  var stripe = loadStripe(user.organisation.stripe_key);
  const appearance = {
    theme: 'stripe'
  };
  const options = {
    appearance
  };
  useEffect(() => {
    if (!data) {
      navigate(paths.configNewDonation, { replace: true });
    }
  }, [navigate]);

  const { donorId, donationItems, subtotal, transactionFees, total } =
    data ?? {};
  const [getDonors, { data: donorsData }] = useLazyQuery(GET_DONORS);
  const { where } = useBranchPermission();

  useEffect(() => {
    getDonors({
      variables: { where }
    });
  }, [where]);

  const currDonorData = useMemo(
    () => donorsData?.donars?.find(donor => donor.id === donorId),
    [donorId, donorsData]
  );
  const { first_name, last_name, score, image } = currDonorData ?? {};
  const name = first_name + ' ' + last_name;

  const [paymentMethods, setPaymentMethods] = useState([]);

  const onSubmitMakePayment = async () => {
    if (!stripe) {
      console.error('Stripe.js has not loaded yet.');
      return;
    }
    onSubmit();
  };

  const [getPaymentMethods, { loading: getPaymentMethodLoading }] = useMutation(
    GET_PAYMENT_METHODS,
    {
      onCompleted: res => {
        setPaymentMethods(res.GetPaymentMethods.payment_methods);
      },
      onError: error => {
        console.log({ error });
        // toast.error('Something went wrong');
      }
    }
  );

  const [
    makePaymentMethodDefault,
    { loading: makePaymentMethodDefaultLoading }
  ] = useMutation(MAKE_PAYMENT_METHOD_DEFAULT, {
    onCompleted: res => {
      toast.success('Payment method Updated');
      console.log(res.GetPaymentMethods.payment_methods);
    },
    onError: error => {
      console.log({ error });
      // toast.error('Something went wrong');
    }
  });

  const onSubmitMakeDefaultMethod = ({ paymentMethodId }) => {
    makePaymentMethodDefault({
      variables: {
        donor_id: data.donorId,
        paymentMethodId: paymentMethodId
      }
    });
  };

  useEffect(() => {
    getPaymentMethods({ variables: { donor_id: data.donorId } });
  }, [data]);
  return (
    <>
      {data ? (
        <Section className={'py-0'}>
          <Row className="g-3">
            <Col md={8} className="order-1 order-md-0">
              <Card className="mb-3">
                <Card.Header className="pb-0">
                  <Card.Title className="fw-medium">Selected Donor</Card.Title>
                </Card.Header>
                <Card.Body>
                  <DonorCard name={name} score={score} image={image} />
                </Card.Body>
              </Card>
              <Elements stripe={stripe} options={options}>
                <PNPaymentOption
                  loadingPaymentMethods={getPaymentMethodLoading}
                  onSubmitMakePayment={onSubmitMakePayment}
                  loading={loading}
                  data={data}
                  paymentMethods={paymentMethods}
                  onSubmitMakeDefaultMethod={onSubmitMakeDefaultMethod}
                  makePaymentMethodDefaultLoading={
                    makePaymentMethodDefaultLoading
                  }
                />
              </Elements>
            </Col>
            <Col md={4} className="order-0 order-md-1">
              <PNDonationSummary
                items={donationItems}
                subtotal={subtotal}
                transactionFees={transactionFees}
                total={total}
              />
            </Col>
          </Row>
        </Section>
      ) : null}
    </>
  );
};

DonationPayment.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool
};

export default DonationPayment;
